.principal {
    justify-content: center;
    align-items: center;
}

.select-multiple {
    display: flex;
    height: 35px;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7px;
    border: 1px solid var(--Background-Input, #F2F2F2);
    background: var(--Background-Input, #F2F2F2);
}

.principal-enviaremail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divcheckbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.checkcheck {
    margin-right: 6px;
}

.grid-container {
    display: grid;
    grid-template-columns: auto;
    padding: 10px;
    margin-top: 5px;
    width: auto;
}

.grid-item {
    padding: 20px;
    font-size: 30px;
    text-align: center;
}

.MuiTextField-root {
    border: groove !important;
}

.botoes-enviaremail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5%;
    margin-bottom: 5%;
}

.input-text-email {
    width: 100vh;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.botoes-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.indicadores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.indicador-principal-enviaremail {
    color: #4F4F4F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.indicador-principal-2 {
    color: #989898;
}

.indicador-secundario {
    color: #4F4F4F;
    margin-left: 10px;
}

.testeform.css-puhdgc-MuiFormControl-root {
    width: 100vh !important;
}

@media screen and (max-width: 600px) {
    .botoes-footer {
        display: grid;
        grid-template-columns: 170px 150px;
    }
}

@media screen and (max-width: 750px) {
    .grid-container {
        display: flex;
        flex-direction: column;
        width: auto;
    }
}

@media screen and (max-height: 800px) {
    .botoes-enviaremail {
        margin-top: 8%;
    }
}

@media screen and (max-width: 1250px) {
    .input-text-email {
        width: 70vh !important;
    }

    .testeform.css-puhdgc-MuiFormControl-root {
        width: 70vh !important;
    }   
}

@media screen and (max-width: 500px) {
    .input-text-email {
        width: 40vh !important;
    }

    .testeform.css-puhdgc-MuiFormControl-root {
        width: 40vh !important;
    }   
}