.hover-emb:hover .tooltip-novo {
    visibility: visible;
    opacity: 1;
}

.hover-emb {
    display: flex;
    flex-direction: column-reverse; /* Isso faz com que o tooltip fique acima do ícone */
    align-items: center; /* Isso centraliza o tooltip horizontalmente em relação ao ícone */
    position: relative;
    cursor: pointer;
}

.tooltip-novo {
    position: absolute;
    transform: translateX(-50%);
    visibility: hidden;
    top: -30px;
    left: 18px;
    transition: opacity 150ms, visibility 150ms;
}

.icone-novo:hover {
    transform: rotate(90deg);
    background-color: #F0F6FF;
}

.texto-novo {
    color: #083FA6;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 25px;
    background: #F0F6FF;
    padding: 5px 10px;
}

.icone-novo {
    display: flex;
    padding: 12px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 25px;
    border: 1px solid var(--Background-Input, #F2F2F2);
    background: var(--Background-Input, #F2F2F2);
    transform: rotate(0deg);
    transition: 150ms;
}