.indicadores-aguardaCTE {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 40px;
}

.fat-botoes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.config-dropdown-none {
    position: absolute;
    right: -12px;
    bottom: 50px;
    width: 180px;
    background-color: #dc2d2d;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: slideDown 0.3s ease-out;
}

.config-dropdown-none p {
    padding: 10px;
    margin: 0;
    color: white;
    cursor: pointer;
}

.item-projecao {
    width: 55%;
}

.principal-projecao {
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
}

.flex2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.flex3 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.texto-tela-cheia {
    color: var(--basics-black, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-left: 10px;
    line-height: normal;
}

.flex-linhas {
    display: flex;
    flex-direction: column;
}

.imagens-aguardaCTEs {
    cursor: pointer;
    margin: 0 0 0 15px;
}

.aguardaCTEs {
    display: flex;
    flex-direction: column;
}

.header-aguardaCTEs {
    display: flex;
    padding: 16px 40px 0 40px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.indicador-principal-aguardaCTE-v2 {
    color: var(--basics-black, #000);
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.container-aguardaCTE-card {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 10px;
    padding: 20px;
    justify-content: center;
    margin-bottom: 20px;
}

.container-aguardaCTE-card2 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 30px;
    justify-content: center;
}

.card-aguardaCTE {
    border: 1px solid #C3D8E3;
    background: #E8F7FF;
    border-radius: 10px;
    width: 100px;
    height: 83px;
    padding: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.card-header {
    font-weight: bold;
    padding-bottom: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--Baby-Blue-Font, #002D45);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.card-body {
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--Baby-Blue-Font, #002D45);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.corpoModalMetas {
    height: 100%;
    outline: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.corpoModalMetas .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    position: fixed !important;
    margin: 0px !important;
    width: 40% !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}