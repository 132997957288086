.div-modal-proposta {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.content-proposta {
    height: auto;
    min-height: 140px;
    width: auto;
}

.action-proposta {
    margin-top: 10px;
}

.delete {
    font-size: 20px;
}

.proposta-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}