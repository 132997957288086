.div-politicas-privacidade {
    margin-left: 20%;
    margin-right: 20%;
    margin-top: -120px;
    margin-bottom: -120px;
    padding: 15px;
    background-color: wheat;
    border-radius: 20px;
    position: absolute;
}

.header-footer {
    height: 180px;
    background-color: #0946B8;
}