.principal-mapa {
    width: 100%;
    height: 96vh;
}

.rastreio-card {
    position: absolute;
    top: 150px;
    right: 10px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 250px;
    z-index: 10;
}

.eventos-card {
    position: absolute;
    top: 150px;
    right: 52px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 250px;
    z-index: 10;
}

.voltar-card {
    position: absolute;
    top: 100px;
    right: 45px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 250px;
    z-index: 10;
}

.rastreio-card h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
}

.rastreio-card p {
    font-size: 14px;
    margin-bottom: 5px;
}
