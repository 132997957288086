.principal-codigo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.input-codigoemail {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    width: 100%;
    padding: 10px;
}

.card {
    position: relative;
    width: 25%;
    margin: 20vh calc(100px + 20vw) 0 0;
    background-color: rgba(255, 255, 255, 0.7);
    height: 80%;
    z-index: 9999;
    border-radius: 20px;
}
.decoration{
    color: #0946B8;
    text-decoration: none;
    font-size: 16px;
}


.texto-informe {
    text-align: center;
}

@media screen and (max-width: 600px) {
    .card {
        width: 80%;
        margin-top: 15vh;
        height: 90%;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .card {
        width: 50%;
        margin-top: 15vh;
        height: 90%;
    }
}

@media screen and (min-width: 1400px){
    .card {
        width: 17%;
        margin-top: 17%;
        height: 100%;
    }
}