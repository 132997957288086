.fundo {
    height: 100%;
}

.divImagem {
    margin-top: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-carrosel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 20px;
    border-radius: 10px;
    background: rgba(101, 121, 177, 0.2);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.imagemHome {
    width: 50%;
    height: 50%;
}

.corpoModalCookies {
    height: 100%;
    outline: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.corpoModalCookies .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    bottom: 0;
    position: fixed !important;
    margin: 0px !important;
    width: 100% !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
}

.MuiPaper-root {
    max-width: 200vh !important;
}

.texto-link {
    margin-right: 25px;
}

.botaoConcluir {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px;
}

@media screen and (max-width: 450px) {
    .texto-cookies {
        padding: 5%;
    }

    .botaoConcluir {
        padding: 5%;
    }

    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        flex-direction: column !important;
    }
}