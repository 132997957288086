.div-modal-ontime {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.content-ontime {
    height: 120px;
    width: 550px;
}

.action-ontime {
    margin-top: 10px;
}

.delete {
    font-size: 20px;
}

.coluna-modalmotivo {
    display: flex;
    align-items: flex-end;
    gap: 2px;
    justify-content: center;
    margin-top: 15px;
}