.div-modal-cadastro {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.titulo-modal-cadastro {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}

.user {
    font-size: 20px;
}

.cadastro-data {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}