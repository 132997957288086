b {
    font-size: 14px;
}

p {
    font-size: 14px;
}

.apexcharts-toolbar {
    display: none !important;
}

.texto-dash {
    display: flex;
    flex-direction: row;
    justify-content: end;
}

@media print {
    .texto-dash {
        display: none;
    }
}