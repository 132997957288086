.div-filtrar-pedido {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 420px;
    margin: -18px;
    margin-left: 5%;
}

.card-cidade-filial {
    display: flex;
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5px;
    border-radius: 35px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--basics-white, #FFF);
    cursor: pointer;
}

.card-filtro {
    display: inline-flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 35px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--basics-white, #FFF);
    cursor: pointer;
}

.card-filtro-multiple {
    display: inline-flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 35px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--basics-white, #FFF);
   max-width: 200px;
}

.card-grupo-cliente {
    display: inline-flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 35px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--basics-white, #FFF);
    cursor: pointer;
}

.pointer-filtro {
    cursor: pointer;
}

.div-datas-v2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
    margin: 0 10px;
}

.div-datas-margem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    width: auto;
    margin-left: 5px;
}

.div-datas-margem-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    width: auto;
    margin-right: 5px;
}

.input-filtro-data-v2 {
    border-radius: 10px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--ColorImput, #FAFAFA);
    padding: 10px;
    width: 100%;
}

.input-filtro-date {
    border-radius: 10px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--ColorImput, #FAFAFA);
    padding: 10px;
}

.space {
    stroke-width: 1px;
    stroke: var(--Border, #C2C2C2);
}

.texto-filtro {
    color: var(--basics-black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.div-filtrar-cliente {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 420px;
    margin: -10px;
    margin-left: 32%;
}

@media screen and (max-width: 700px) {
    .div-filtrar-cliente {
        margin-left: 5%;
    }
}

.div-filtrar-veiculo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 420px;
    margin: -40px;
    margin-left: 58%;
}

.div-filtrar-embarque-v2 {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0 !important;
    border-radius: 5px;
    z-index: 999;
}

.div-filtrar-dash {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 820px;
    margin: -18px;
    margin-left: 30px;
}

.div-filtrar-faturamento {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 820px;
    margin: -18px;
    margin-top: -40px;
    margin-left: 30px;
}

.div-filtrar-faturamento-v2 {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 0 !important;
    border-radius: 5px;
    z-index: 999;
}

.select-options {
    position: absolute;
    top: 45px;
    border-radius: 10px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--basics-white, #FFF);
    width: 187px;
    max-height: 350px;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.select-options-cliente {
    position: absolute;
    top: 45px;
    border-radius: 10px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--basics-white, #FFF);
    width: 187px;
    max-height: 350px;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.select-options-pedido {
    position: absolute;
    top: 45px;
    border-radius: 10px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--basics-white, #FFF);
    width: 187px;
    max-height: 350px;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.select-options2 {
    position: absolute;
    top: 45px;
    border-radius: 10px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--basics-white, #FFF);
    width: 187px;
    max-height: 350px;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.select-options4 {
    position: absolute;
    top: 176px;
    left: 1055px;
    border-radius: 10px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--basics-white, #FFF);
    width: 210px;
    max-height: 350px;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.select-options3 {
    position: absolute;
    top: 160px;
    left: 978px;
    border-radius: 10px;
    border: 1px solid var(--Border, #C2C2C2);
    background: var(--basics-white, #FFF);
    width: 187px;
    max-height: 350px;
    overflow-y: auto;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.pointer {
    cursor: pointer;
}

.option {
    padding: 5px 20px;
    cursor: pointer;
    color: var(--basics-black, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.option2 {
    padding: 5px 20px;
    color: var(--basics-black, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.option3 {
    padding: 5px 20px;
    color: var(--basics-black, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.option4 {
    padding: 5px 20px;
    cursor: default;
    color: var(--basics-black, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.option:hover {
    background-color: #f0f0f0;
}

.option.selected {
    background-color: #c2c2c2;
}

@media screen and (max-width: 800px) {
    .div-filtrar-embarque-v2 {
        flex-direction: column;
        width: auto;
        align-items: start;
        justify-content: start;
    }
}