.principal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12%;
}

.MuiTextField-root {
    border: groove !important;
}

.botoes-documentacao {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center !important;
    margin-top: 8% !important;
}

.input-text {
    width: 250px;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.botoes-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.indicadores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.indicador-principal {
    color: #4F4F4F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.indicador-principal-2 {
    color: #989898;
}

.indicador-secundario {
    color: #4F4F4F;
    margin-left: 10px;
}

@media screen and (max-width: 600px) {
    .botoes-footer {
        display: grid;
        grid-template-columns: 170px 150px;
    }
}


@media screen and (max-height: 800px) {
    .botoes-documentacao {
        margin-top: 8%;
    }

    .principal {
        margin-top: 8%;
    }
}

.documentos-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin: 20px auto;
    padding: 20px;
    max-width: 1200px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-documento {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 350px;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.documento-label {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

.preview-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.preview-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #ccc;
}

.upload-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.input-file {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    font-size: 0.9rem;
    width: 100%;
    cursor: pointer;
}

.input-date {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 5px;
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
}

button {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 100px;
}

button:hover {
    background-color: #0056b3;
}

button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .documentos-container {
        flex-direction: column;
        align-items: center;
    }

    .card-documento {
        width: 100%;
        max-width: 300px;
    }
}