.principal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12%;
}

.MuiTextField-root {
    border: groove !important;
}

.configuracoes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ajusta o número de colunas dinamicamente */
    gap: 20px; /* Espaçamento entre os itens */
    justify-content: center; /* Centraliza os itens horizontalmente */
    padding: 20px; /* Padding ao redor da grid */
}

.grid-item {
    display: flex;
    flex-direction: column; /* Alinha o label e o input em coluna */
    align-items: center;
}

.grid-item-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.botoes-Diarias {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center !important;
    margin-top: 8% !important;
}

.input-text {
    width: 250px;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.botoes-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.indicadores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.indicador-principal {
    color: #4F4F4F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.indicador-principal-2 {
    color: #989898;
}

.indicador-secundario {
    color: #4F4F4F;
    margin-left: 10px;
}

.tr-diaria:hover {
    background-color: #989898;
}

.td-diaria {
    font-weight: bold;
}

@media screen and (max-width: 600px) {
    .botoes-footer {
        display: grid;
        grid-template-columns: 170px 150px;
    }
}


@media screen and (max-height: 800px) {
    .botoes-Diarias {
        margin-top: 8%;
    }
    .principal {
        margin-top: 8%;
    }
}