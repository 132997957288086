.principal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12%;
}

.text-ontime {
    text-align: center;
}

.card-header-ontime {
    font-weight: bold;
    padding-bottom: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    color: var(--Baby-Blue-Font, #002D45);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.div-card-ontime {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.card-ontime,
.card-nao-ontime,
.card-ontime-azul {
    width: calc(50% - 20px);
    margin-bottom: 20px;
}

.container-faturamento-ontime {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 10px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    max-width: 70%;
    margin-left: calc(15% + 50px);
}

.card-ontime {
    border: 1px solid #3ec049;
    background: #a5ffb4;
    border-radius: 10px;
    width: 150px;
    height: 90px;
    padding: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.card-nao-ontime {
    border: 1px solid #ff2525;
    background: #ff9595;
    border-radius: 10px;
    width: 150px;
    height: 90px;
    padding: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.card-ontime-azul {
    border: 1px solid #C3D8E3;
    background: #E8F7FF;
    border-radius: 10px;
    width: 150px;
    height: 90px;
    padding: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.principal-ontime {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    padding: 10px 20px;
}

.container-ontime {
    width: 50%;
}

.MuiTextField-root {
    border: groove !important;
}

.botoes-onTime {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center !important;
    margin-top: 8% !important;
}

.input-text {
    width: 250px;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.botoes-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.indicadores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.indicador-principal {
    color: #4F4F4F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.indicador-principal-2 {
    color: #989898;
}

.indicador-secundario {
    color: #4F4F4F;
    margin-left: 10px;
}

@media screen and (max-width: 600px) {
    .botoes-footer {
        display: grid;
        grid-template-columns: 170px 150px;
    }
}


@media screen and (max-height: 800px) {
    .botoes-onTime {
        margin-top: 8%;
    }

    .principal {
        margin-top: 8%;
    }
}

@media screen and (max-width: 1300px) {
    .container-faturamento-ontime {
        max-width: 85%;
        margin-left: 8%;
    }
}

@media screen and (max-width: 1000px) {
    .container-faturamento-ontime {
        max-width: 95%;
        margin-left: 2%;
    }
}
