/* Modal base styles */
.modal {
    position: fixed;
    left: 50%; /* Centraliza horizontalmente */
    bottom: 0; /* Alinha ao fundo da página */
    transform: translateX(-50%); /* Ajusta o posicionamento horizontal para verdadeiramente centralizar */
    width: 40%; /* Largura relativa */
    min-height: 25%; /* Altura mínima */
    display: flex;
    justify-content: center;
    align-items: end;
    visibility: hidden; /* Oculto por padrão */
    z-index: -1; /* Posiciona atrás do conteúdo */
}

.modal.open {
    visibility: visible; /* Mostra a modal quando tiver a classe 'open' */
    z-index: 1000; /* Traz para a frente */
}

.modal.closed {
    visibility: hidden; /* Esconde a modal */
}

.modal-backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo semi-transparente */
    pointer-events: none; /* Permite clique através do fundo */
}

.div-modal-previsao {
    background: #cbd9ff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    width: 90%;
    max-width: 800px;
}

.modal-title {
    font-size: 1.5em;
    margin-bottom: 15px;
    text-align: center;
}

.modal-content {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
}

.card-previsao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-previsao {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
