.teste {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rastreio {
    cursor: pointer;
    color: #5353ef;
    text-decoration: underline;
}

.outer-wrapper {
    border-radius: 4px;
    max-width: fit-content;
    max-height: fit-content;
}

.table-wrapper {
    overflow-y: scroll;
    overflow-x: scroll;
    height: fit-content;
    max-height: 80vh;
    margin-top: 22px;
    margin: 30px;
    padding-bottom: 20px;
}

.table-wrapper-faturamento {
    overflow-y: scroll;
    overflow-x: scroll;
    height: fit-content;
    max-height: 80vh;
    margin-top: 22px;
    margin: 30px;
    margin-top: 70px;
    padding-bottom: 20px;
}

.table-wrapper-entregas {
    overflow-y: scroll;
    overflow-x: scroll;
    height: fit-content;
    max-height: 80vh;
    margin-left: 50px;
}

.outer-wrapper-cidade {
    border-radius: 4px;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.outer-wrapper-rota {
    border-radius: 4px;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.outer-wrapper-veiculo {
    border-radius: 4px;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.outer-wrapper-faturamento {
    border-radius: 4px;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -16px;
}

.outer-wrapper-pedido {
    border-radius: 4px;
    max-height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.table-wrapper-cidade {
    overflow-y: scroll;
    overflow-x: scroll;
    height: fit-content;
    max-height: 80vh;
    margin-top: 22px;
    margin: 15x;
    padding-bottom: 20px;
}

table {
    min-width: max-content;
    border-collapse: separate;
    border-spacing: 0px;
}

table td {
    text-align: left;
    padding: 2px;
    font-size: 12px;
}

th {
    text-align: center !important;
    padding: 2px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    font-size: 13px !important;
}

.table-break {
    table-layout: fixed;
    min-width: auto;
    border-collapse: separate;
    border-spacing: 0px;
}

.td-break {
    text-align: left;
    padding: 10px;
    font-size: 12px;
    word-wrap: break-word;
}

.td-center {
    text-align: center;
}

.td-right {
    text-align: right;
}

.cte {
    background-color: rgb(166, 255, 163, 0.5) !important;
}

.cancelado {
    background-color: rgba(255, 0, 0, 0.5) !important;
}

.striped:nth-child(even) {
    background-color: #e2e2e2;
}

.hover:hover {
    background-color: #bebebe !important;
}

.selecionado {
    background-color: #909090 !important;
}

.config-tabela {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: end;
    margin-left: 250px;
    margin-top: -40px;
}

.input-text-motivo {
    width: 80vh;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.v-entrega {
    text-align: left !important;
}

span {
    margin: 8px;
}

.span-margin {
    margin: 19px;
}

.icon-entrega {
    margin: -2px;
    width: 15px !important;
    height: 15px !important;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .table-wrapper-cidade {
        max-height: 54vh;
    }
}

@media screen and (max-width: 600px) {
    .table-wrapper {
        max-height: 54vh;
    }

    .table-wrapper-faturamento {
        max-height: 54vh;
    }
}

@media screen and (max-width: 700px) {
    /* .outer-wrapper-rota {
        border-radius: 4px;
        max-width: fit-content;
        max-height: fit-content;
        display: block;
    } */

    .outer-wrapper-veiculo {
        border-radius: 4px;
        max-width: fit-content;
        max-height: fit-content;
        display: block;
    }

    .outer-wrapper-faturamento {
        border-radius: 4px;
        max-width: fit-content;
        max-height: fit-content;
        display: block;
        margin-top: -16px;
    }
}

@media screen and (max-width: 1950px) {
    .outer-wrapper-pedido {
        border-radius: 4px;
        max-width: fit-content;
        max-height: fit-content;
        display: block;
    }
}

@media screen and (min-width: 720px) and (max-width: 1400px) {
    .table-wrapper-cidade {
        max-height: 72vh;
    }
}

@media screen and (min-width: 720px) and (max-width: 1400px) {
    .table-wrapper {
        max-height: 72vh;
    }
    
    .table-wrapper-faturamento {
        max-height: 72vh;
    }
}

.div-buscas {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2vh;
}

@media screen and (max-width: 1000px) {
    .div-buscas {
        display: flex;
        flex-direction: column;
    }
}


.click-configurar {
    cursor: pointer;
}

.filtro {
    display: flex;
    flex-direction: row;
    padding-left: 1vh;
    padding-right: 40px;
}

.click-filtro {
    cursor: pointer;
    padding-top: 1vh;
}

.csv-botao {
    color: black;
    margin-bottom: -2px;
}

.butao-csv {
    color: black !important;
}

.div-data {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    width: auto;
    background: #DFDFDF;
    border-radius: 5px;
}

.div-datas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    width: auto;
    margin: 0 5px;
}

/* .input-text {
    width: 100px;
    padding: 5px 5px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
} */

@media screen and (max-width: 1250px) {
    .input-text-motivo {
        width: 50vh !important;
    }
}

@media screen and (max-width: 500px) {
    .input-text-motivo {
        width: 20vh !important;
    }

    .config-tabela {
        flex-direction: column;
    }
}