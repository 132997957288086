.container-sem-autorizacao {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    margin-top: 10%;
}

.tituloSemAuto {
    color: red;
}