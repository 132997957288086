* {
  margin:0;
  padding:0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

html,body , #root {
  background-color: #FFF;
  min-height: 100vh;
  flex-grow: 1;
  height: 100%;
}
