.principal-codigo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.card {
    position: relative;
    width: 25%;
    margin-top: 15%;
    height: 80%;
}

.texto-informe {
    text-align: center;
}

@media screen and (max-width: 600px) {
    .card {
        width: 80%;
        margin-top: 15vh;
        height: 90%;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .card {
        width: 50%;
        margin-top: 15vh;
        height: 90%;
    }
}

@media screen and (min-width: 1400px){
    .card {
        width: 17%;
        margin-top: 17%;
        height: 100%;
    }
}