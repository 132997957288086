.carrossel-vertical {
  width: 100%;
  max-width: 100%;
}

.aviso-card {
  margin: 10px 0 10px 0;
  text-align: center;
  padding: 23px 40px;
  justify-content: start;
  align-items: center;
  display: flex !important;
  flex-direction: row !important;
  gap: 10px;
  border-radius: 10px;
  background: radial-gradient(50% 50% at 50% 50%, #F0F0F0 0%, #F3F3F3 100%);
}

.aviso-card p {
  font-size: 16px;
  color: #333;
}

.slick-track {
  height: 280px !important;
}

.carrossel-vertical .slick-dots {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  display: flex !important;
  flex-direction: column;
}

.carrossel-vertical .slick-dots li {
  margin: 5px 0;  /* ou qualquer outro valor para ajustar o espaçamento */
}

.carrossel-vertical .slick-dots li button {
  width: 10px;   /* ajuste conforme necessário */
  height: 10px;  /* ajuste conforme necessário */
  border-radius: 50%; 
}

.carrossel-vertical .slick-dots li button:before {
  font-size: 10px;  /* ajuste conforme necessário */
  line-height: 10px;  /* ajuste conforme necessário */
}
