.principal-motorista {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.botoes-motora {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center !important;
    margin-top: 2% !important;
}

.divcheckbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.checkcheck {
    margin-right: 6px;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    margin-top: 8%;
    width: 80vh;
}

.grid-item {
    padding: 20px;
    font-size: 30px;
    text-align: center;
}

.MuiTextField-root {
    border: groove !important;
}

.botoes-motorista {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 12%;
}

.input-text {
    width: 250px;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.botoes-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.indicadores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.indicador-principal-motorista {
    color: #4F4F4F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.indicador-principal-2 {
    color: #989898;
}

.indicador-secundario {
    color: #4F4F4F;
    margin-left: 10px;
}

@media screen and (max-width: 600px) {
    .botoes-footer {
        display: grid;
        grid-template-columns: 170px 150px;
    }
}

@media screen and (max-width: 750px) {
    .grid-container {
        display: flex;
        flex-direction: column;
        width: auto;
    }
}

@media screen and (max-height: 800px) {
    .botoes-motorista {
        margin-top: 8%;
    }
}