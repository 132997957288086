.div-modal-status-smp {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    max-height: 650px;
}

.delete {
    font-size: 20px;
}

.p-negrito {
    font-weight: bold;
}