.div-modal-usuario {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    gap: 20px;
}

.icone-remover {
    position: relative;
    border-radius: 25px;
    background: var(--red-salmon-red-salmon-50, #FBE6E6);
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    transform: rotate(0deg);
    transition: 150ms;
    cursor: pointer;
}

.icone-remover:hover {
    transform: rotate(90deg);
}

.icone-novo {
    position: relative;
    border-radius: 25px;
    background: var(--red-salmon-red-salmon-50, #FBE6E6);
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    transform: rotate(0deg);
    transition: 150ms;
    cursor: pointer;
}

.icone-novo:hover {
    transform: rotate(90deg);
}

.user {
    font-size: 20px;
}

.multiplos-data {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.conteudo-modal-filtros {
    min-height: 600px; /* Substitua X pelo valor desejado para a altura mínima */
}