.principal-frotas {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.indicadores-frota {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.indicador-principal-frota {
    color: #4F4F4F;
    padding: 16px 130px 16px 145px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
