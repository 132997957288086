.icone-fechar {
    position: relative;
    border-radius: 25px;
    background: var(--red-salmon-red-salmon-50, #FBE6E6);
    display: flex;
    padding: 10px;
    align-items: flex-start;
    gap: 10px;
    transform: rotate(0deg);
    transition: 150ms;
}

.tooltip {
    position: absolute;
    top: -35px;
    right: -60px;
    transform: translateX(-50%);
    visibility: hidden;
    transition: opacity 150ms, visibility 150ms;
}

.hover-emb:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.icone-fechar:hover {
    transform: rotate(90deg);
}

.texto-fechar {
    color: var(--red-salmon-red-salmon-800, #5A1212);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 25px;
    background: var(--red-salmon-red-salmon-50, #FBE6E6);
    padding: 10px 20px;
}