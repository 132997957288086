.trash-v2 {
    cursor: pointer;
    margin-top: 10px;
}

.css-cidq4n-singleValue {
    max-width: 75% !important;
}

.grid-item-start {
   text-align: start;
   margin: 15px;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cadastro-principal {
    width: 1086px;
    margin-top: 30px;
}

.container-select-emb {
    width: auto;
}

.error-text {
    color: red;
    font-size: 10px !important;
    max-width: 255px;
    word-wrap: break-word;
    position: absolute;
}

.input-emb {
    display: flex;
    height: 38px;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    border: 1px solid var(--Background-Input, #F2F2F2);
    background: var(--Background-Input, #F2F2F2);
}

.input-emb-erro {
    border: 2px solid red !important;
    display: flex;
    height: 38px;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    border: 1px solid var(--Background-Input, #F2F2F2);
    background: var(--Background-Input, #F2F2F2);
}

.input-emb:hover {
    border: 'none';
    background: var(--Background-Input, #F0F6FF);
}

.dados-embarque-text {
    color: var(--basics-black, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
}

.input-label-emb {
    color: var(--basics-black, #000);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 5px;
    position: sticky;
}

.input-label-emb-2 {
    color: var(--basics-black, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
}

.coluna-entrega-emb {
    display: flex;
    width: 1086px;
    align-items: end;
    gap: 10px;
    flex-shrink: 0;
    margin-top: 40px;
}

.coluna-1-emb {
    display: flex;
    width: 1086px;
    align-items: end;
    gap: 10px;
    flex-shrink: 0;
}

.coluna-2-emb {
    display: flex;
    width: 1086px;
    align-items: end;
    gap: 10px;
    margin-top: 10px;
    flex-shrink: 0;
}

.coluna-3-emb {
    display: flex;
    width: 1086px;
    align-items: end;
    gap: 10px;
    margin-top: 20px;
    flex-shrink: 0;
}

.coluna-emb {
    display: flex;
    align-items: end;
    gap: 2px;
    flex-shrink: 0;
}

.coluna-emb-erro {
    display: flex;
    align-items: end;
    gap: 2px;
    flex-shrink: 0;
}

.parent2 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(11, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 50px;
    padding: 50px;
}

.inputGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 50px;
    padding: 10px 50px 0 50px;
    background-color: rgba(124, 124, 124, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.card-descricao {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(124, 124, 124, 0.4);
    border-radius: 20px;
    margin: 0 50px 0 50px;
}

.grid-item-entrega {
    text-align: center;
}

h5 {
    text-align: center;
    margin: 10px;
}

.input-text-embarque {
    width: 250px;
    padding: 12px 12px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.input-text-descricao {
    width: 30%;
    padding: 12px 12px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.card-entregas {
    padding: 50px;
}

.select-class {
    border: groove !important;
    padding: 12px 20px !important;
    margin: 8px 0 !important;
    box-sizing: border-box !important;
    background-color: white;
}

.botao-mais {
    padding: 0 50px 30px 0;
    display: flex;
    justify-content: end;
}

.info-icon {
    margin-left: 10px;
}

.div-template {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    width: auto;
    margin-top: 20px;
}

.div-template-v2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.expiracao-hora {
    margin-right: 50px;
}

.divA {
    grid-area: 1 / 1 / 6 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(124, 124, 124, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.divB {
    grid-area: 1 / 3 / 9 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(124, 124, 124, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.divBB {
    grid-area: 6 / 1 / 11 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(124, 124, 124, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.divC {
    grid-area: 1 / 1 / 2 / 3;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divD {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divD2 {
    grid-area: 5 / 1 / 5 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divE2 {
    grid-area: 5 / 1 / 5 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divE {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divF {
    grid-area: 3 / 1 / 4 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divG {
    grid-area: 2 / 3 / 2 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divH {
    grid-area: 3 / 2 / 3 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divI {
    grid-area: 4 / 1 / 5 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divJ {
    grid-area: 6 / 4 / 6 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divK {
    grid-area: 6 / 5 / 6 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divL {
    grid-area: 1 / 3 / 2 / 5;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divM {
    grid-area: 3 / 3 / 3 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divN {
    grid-area: 3 / 4 / 3 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divO {
    grid-area: 4 / 3 / 4 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divP {
    grid-area: 4 / 4 / 4 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divQ {
    grid-area: 5 / 3 / 5 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divR {
    grid-area: 5 / 4 / 5 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divS {
    grid-area: 2 / 4 / 2 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.parent3 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-row-gap: 0px;
    grid-column-gap: 50px;
    padding: 50px;
}

.divA1 {
    grid-area: 1 / 1 / 5 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(124, 124, 124, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.divA2 {
    grid-area: 1 / 3 / 5 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(124, 124, 124, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.divA3 {
    grid-area: 1 / 1 / 2 / 3;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divA4 {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA5 {
    grid-area: 2 / 2 / 3 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA6 {
    grid-area: 3 / 1 / 4 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA7 {
    grid-area: 3 / 2 / 4 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;

}

.divA8 {
    grid-area: 4 / 1 / 5 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA9 {
    grid-area: 4 / 2 / 5 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA10 {
    grid-area: 5 / 1 / 6 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA11 {
    grid-area: 1 / 3 / 2 / 5;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divA12 {
    grid-area: 2 / 3 / 3 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA13 {
    grid-area: 2 / 3 / 3 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA14 {
    grid-area: 3 / 3 / 4 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA15 {
    grid-area: 2 / 4 / 3 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA16 {
    grid-area: 4 / 3 / 5 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA17 {
    grid-area: 3 / 3 / 4 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
}

.divA18 {
    grid-area: 5 / 3 / 6 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divA19 {
    grid-area: 3 / 4 / 4 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
}

.divA20 {
    grid-area: 4 / 3 / 5 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.parent4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 50px;
    padding: 50px;
}

.divB1 {
    grid-area: 1 / 1 / 7 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(124, 124, 124, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.divB2 {
    grid-area: 1 / 3 / 4 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(124, 124, 124, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.divB3 {
    grid-area: 4 / 1 / 7 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(124, 124, 124, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.divB3-1 {
    grid-area: 1 / 1 / 6 / 3;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divB3-2 {
    grid-area: 2 / 1 / 3 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -35px;
}

.divB3-3 {
    grid-area: 3 / 1 / 4 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -25px;
}

.divB3-4 {
    grid-area: 4 / 1 / 5 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -15px;
}

.divB3-5 {
    grid-area: 5 / 1 / 6 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB3-6 {
    grid-area: 5 / 1 / 5 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -10px;
}

.divB4 {
    grid-area: 6 / 1 / 6 / 3;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divB4-2 {
    grid-area: 6 / 2 / 6 / 3;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divB5 {
    grid-area: 7 / 1 / 7 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB6 {
    grid-area: 7 / 2 / 7 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB7 {
    grid-area: 8 / 1 / 8 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB8 {
    grid-area: 8 / 2 / 8 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB5-2 {
    grid-area: 9 / 1 / 9 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divB6-2 {
    grid-area: 9 / 2 / 9 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divB7-2 {
    grid-area: 10 / 1 / 10 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divB8-2 {
    grid-area: 10 / 2 / 10 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divB9 {
    grid-area: 11 / 3 / 11 / 3;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divB9-2 {
    grid-area: 12 / 4 / 12 / 4;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divB10 {
    grid-area: 7 / 3 / 7 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB11 {
    grid-area: 7 / 4 / 7 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB12 {
    grid-area: 8 / 3 / 8 / 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB13 {
    grid-area: 8 / 4 / 8 / 4;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB14 {
    grid-area: 1 / 3 / 5 / 5;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divB15 {
    grid-area: 2 / 3 / 3 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB16 {
    grid-area: 3 / 3 / 4 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
}

.divB17 {
    grid-area: 4 / 3 / 7 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(124, 124, 124, 0.4);
    padding: 20px;
    border-radius: 20px;
}

.divB18 {
    grid-area: 4 / 3 / 5 / 5;
    color: black !important;
    border-radius: 20px;
    border: groove;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 30px;
}

.divB19 {
    grid-area: 5 / 3 / 6 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -15px;
}

.divB20 {
    grid-area: 6 / 3 / 7 / 5;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.divB21 {
    grid-area: 7 / 3 / 7 / 5;
    display: flex;
    flex-direction: column;
    align-items: end;
}

@media screen and (max-width: 1250px) {
    .inputGrid {
        grid-template-columns: 1fr 1fr;
    }

    .parent2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(17, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 15px;
    }

    .divA {
        grid-area: 1 / 1 / 6 / 3;
    }

    .divBB {
        grid-area: 6 / 1 / 9 / 3;
    }

    .divB {
        grid-area: 9 / 1 / 18 / 3;
    }

    .divC {
        grid-area: 1 / 1 / 2 / 3;
    }

    .divD {
        grid-area: 2 / 1 / 3 / 2;
    }

    .divE {
        grid-area: 2 / 2 / 3 / 3;
    }

    .divF {
        grid-area: 3 / 1 / 4 / 2;
    }

    .divG {
        grid-area: 10 / 1 / 10 / 3;
    }

    .divH {
        grid-area: 4 / 1 / 5 / 2;
    }

    .divI {
        grid-area: 3 / 2 / 5 / 3;
    }

    .divB4 {
        grid-area: 6 / 1 / 6 / 3;
    }

    .divB5 {
        grid-area: 7 / 1 / 7 / 2;
    }

    .divB6 {
        grid-area: 8 / 2 / 7 / 3;
    }

    .divB7 {
        grid-area: 9 / 1 / 8 / 2;
    }

    .divB8 {
        grid-area: 9 / 2 / 8 / 3;
    }

    .divL {
        grid-area: 9 / 1 / 9 / 3;
    }

    .divM {
        grid-area: 11 / 1 / 11 / 2;
    }

    .divN {
        grid-area: 11 / 2 / 11 / 3;
    }

    .divO {
        grid-area: 12 / 1 / 12 / 2;
    }

    .divP {
        grid-area: 12 / 2 / 12 / 3;
    }

    .divQ {
        grid-area: 13 / 1 / 13 / 2;
    }

    .divR {
        grid-area: 13 / 2 / 13 / 3;
    }

    .divJ {
        grid-area: 14 / 1 / 14 / 2;
    }

    .divK {
        grid-area: 14 / 2 / 14 / 3;
    }

    .divS {
        grid-area: 15 / 1 / 15 / 3;
    }

    .divB10 {
        grid-area: 16 / 1 / 16 / 2;
    }

    .divB11 {
        grid-area: 16 / 2 / 16 / 3;
    }

    .divB12 {
        grid-area: 17 / 1 / 17 / 2;
    }

    .divB13 {
        grid-area: 17 / 2 / 17 / 3;
    }

    .parent3 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(8, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 15px;
    }

    .divA1 {
        grid-area: 1 / 1 / 5 / 3;
    }

    .divA2 {
        grid-area: 5 / 1 / 9 / 3;
    }

    .divA3 {
        grid-area: 1 / 1 / 2 / 3;
    }

    .divA4 {
        grid-area: 2 / 1 / 3 / 2;
    }

    .divA5 {
        grid-area: 2 / 2 / 3 / 3;
    }

    .divA6 {
        grid-area: 3 / 1 / 4 / 2;
    }

    .divA7 {
        grid-area: 3 / 2 / 4 / 3;
    }

    .divA8 {
        grid-area: 4 / 1 / 5 / 2;
    }

    .divA9 {
        grid-area: 4 / 2 / 5 / 3;
    }

    .divA11 {
        grid-area: 5 / 1 / 6 / 3;
    }

    .divA13 {
        grid-area: 6 / 1 / 7 / 2;
    }

    .divA15 {
        grid-area: 6 / 2 / 7 / 3;
    }

    .divA17 {
        grid-area: 7 / 1 / 8 / 2;
    }

    .divA19 {
        grid-area: 7 / 2 / 8 / 3;
    }

    .divA20 {
        grid-area: 8 / 1 / 9 / 3;
    }
}

@media screen and (max-width: 600px) {
    .inputGrid {
        grid-template-columns: 1fr;
    }

    .parent2 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(27, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 15px;
    }

    .css-m5vj9m-MuiStepper-root {
        flex-direction: column !important;
    }

    .divA {
        grid-area: 1 / 1 / 9 / 2;
    }

    .divBB {
        grid-area: 9 / 1 / 14 / 2;
    }

    .divB {
        grid-area: 14 / 1 / 28 / 2;
    }

    .divC {
        grid-area: 1 / 1 / 2 / 2;
    }

    .divD {
        grid-area: 2 / 1 / 3 / 2;
        margin-top: -80px;
    }

    .divE {
        grid-area: 3 / 1 / 4 / 2;
        margin-top: -100px;
    }

    .divF {
        grid-area: 4 / 1 / 5 / 2;
        margin-top: -100px;
    }

    .divG {
        grid-area: 5 / 1 / 6 / 2;
        margin-top: -100px;
    }

    .divH {
        grid-area: 6 / 1 / 7 / 2;
        margin-top: -100px;
    }

    .divI {
        grid-area: 7 / 1 / 8 / 2;
        margin-top: -100px;
    }

    .divB4 {
        grid-area: 9 / 1 / 10 / 2;
    }

    .divB5 {
        grid-area: 10 / 1 / 11 / 2;
    }

    .divB6 {
        grid-area: 11 / 1 / 12 / 2;
    }

    .divB7 {
        grid-area: 12 / 1 / 13 / 2;
    }

    .divB8 {
        grid-area: 13 / 1 / 14 / 2;
    }

    .divL {
        grid-area: 14 / 1 / 15 / 2;
    }

    .divM {
        grid-area: 15 / 1 / 16 / 2;
    }

    .divN {
        grid-area: 16 / 1 / 17 / 2;
    }

    .divO {
        grid-area: 17 / 1 / 18 / 2;
    }

    .divP {
        grid-area: 18 / 1 / 19 / 2;
    }

    .divQ {
        grid-area: 19 / 1 / 20 / 2;
    }

    .divR {
        grid-area: 20 / 1 / 21 / 2;
    }

    .divJ {
        grid-area: 21 / 1 / 22 / 2;
    }

    .divK {
        grid-area: 22 / 1 / 23 / 2;
    }

    .divS {
        grid-area: 23 / 1 / 24 / 2;
    }

    .divB10 {
        grid-area: 24 / 1 / 25 / 2;
    }

    .divB11 {
        grid-area: 25 / 1 / 26 / 2;
    }

    .divB12 {
        grid-area: 26 / 1 / 27 / 2;
    }

    .divB13 {
        grid-area: 27 / 1 / 28 / 2;
    }

    .parent3 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(13, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 15px;
    }

    .divA1 {
        grid-area: 1 / 1 / 8 / 2;
    }

    .divA2 {
        grid-area: 8 / 1 / 14 / 2;
    }

    .divA3 {
        grid-area: 1 / 1 / 2 / 2;
        margin-top: 0PX;
    }

    .divA4 {
        grid-area: 2 / 1 / 3 / 2;
        margin-top: 0PX;
    }

    .divA5 {
        grid-area: 3 / 1 / 4 / 2;
        margin-top: 0PX;
    }

    .divA6 {
        grid-area: 4 / 1 / 5 / 2;
        margin-top: 0PX;
    }

    .divA7 {
        grid-area: 5 / 1 / 6 / 2;
        margin-top: 0PX;
    }

    .divA8 {
        grid-area: 6 / 1 / 7 / 2;
        margin-top: 0PX;
    }

    .divA9 {
        grid-area: 7 / 1 / 8 / 2;
        margin-top: 0PX;
    }

    .divA11 {
        grid-area: 8 / 1 / 9 / 2;
        margin-top: 0PX;
    }

    .divA13 {
        grid-area: 9 / 1 / 10 / 2;
        margin-top: 0PX;
    }

    .divA15 {
        grid-area: 10 / 1 / 11 / 2;
        margin-top: 0PX;
    }

    .divA17 {
        grid-area: 11 / 1 / 12 / 2;
        margin-top: 0PX;
    }

    .divA19 {
        grid-area: 12 / 1 / 13 / 2;
        margin-top: 0PX;
    }

    .divA20 {
        grid-area: 13 / 1 / 14 / 2;
        margin-top: 0PX;
    }

    .parent4 {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(11, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 15px;
    }

    .divB1 {
        grid-area: 1 / 1 / 6 / 2;
    }

    .divB2 {
        grid-area: 6 / 1 / 9 / 2;
    }

    .divB17 {
        grid-area: 9 / 1 / 12 / 2;
    }

    .divB3-1 {
        grid-area: 1 / 1 / 2 / 2;
    }

    .divB3-2 {
        grid-area: 2 / 1 / 3 / 2;
    }

    .divB3-3 {
        grid-area: 3 / 1 / 4 / 2;
    }

    .divB3-4 {
        grid-area: 4 / 1 / 5 / 2;
    }

    .divB3-5 {
        grid-area: 8 / 1 / 8 / 2;
        margin-top: -15px;
    }

    .divB3-6 {
        grid-area: 5 / 1 / 5 / 2;
    }

    .divB14 {
        grid-area: 6 / 1 / 6 / 2;
    }

    .divB15 {
        grid-area: 7 / 1 / 7 / 2;
    }

    .divB16 {
        grid-area: 8 / 1 / 8 / 2;
    }

    .divB18 {
        grid-area: 9 / 1 / 9 / 2;
    }

    .divB19 {
        grid-area: 10 / 1 / 10 / 2;
    }

    .divB20 {
        grid-area: 11 / 1 / 11 / 2;
    }
}