.imagem {
    margin-top: 10vh;
    width: 15%;
    height: 15%;
}

.desenvolvido {
    text-align: center;
}

.principal {
    justify-content: center;
    align-items: center;
}

.versao {
    padding: 30px;
}

h1 {
    text-align: center;
}

.cardAjuda {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    background-color: white;
    padding: 4% 2%;
    border-radius: 2%;
    width: 60%;
    overflow-y: auto;
    max-height: 80vh;
}

.youtube-video {
    width: 960px;
    height: 540px;
    border: none;
    margin: 20px 0;
    display: block;
    max-width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 700px) {
    .cardAjuda {
        width: 80%;
    }

    .imagem {
        margin-top: 5vh;
        width: 45%;
        height: 45%;
    }
}
