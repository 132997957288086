.div-modal-embarque {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titulo-modal-embarque {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
}

.options {
    cursor: pointer;
    padding: 3px;
    font-size: 20px;
    color: #111;
}

.options:hover {
    background-color: #54517e;
}

.modal-embarque {
    width: 21vw;
    border-radius: 10px;
}

.title-modal-embarque {
    font-size: 1.5em;
    margin: 0.5em 0 0 0;
    position: relative;
    bottom: 8px;
}