.containerEdita {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
}

.image-preview {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: 10px;
    border: 2px solid #ddd;
}

.image-preview img {
    width: 100%;
    height: auto;
}

.form {
    margin-top: 3vh;
}

.input-text-emailsenha {
    width: 350px;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3vh;
}

.botoes {
    margin-top: 3vh;
    margin-right: 22px;
}

@media screen and (max-width: 700px) {
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
    }

    .botoes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        margin-top: 3vh;
    }
}
