.containerCadastra {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
}

.text {
    margin-bottom: 5vh;
}

.containerPermissaoRegras {
    margin-top: 2vh;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    padding: 10px 450px;
}