.div-modal-usuario {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.user {
    font-size: 20px;
}

.usuario-data {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.thzada {
    padding: 4px 16px;
    position: sticky;
    top: 0px;
    background-color: rgb(223, 223, 223);
    color: rgb(79, 79, 79);
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    border: 1px solid rgb(231, 231, 231);
    cursor: pointer;
    border-top-left-radius: 0px !important;
}

input[type="checkbox"] {
    width: 18px;
    height: 18px;
    border: 2px solid #0946B8;
    border-radius: 4px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    outline: none;
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

input[type="checkbox"]:checked {
    background-color: #0946B8;
    border-color: #0946B8;
}

input[type="checkbox"]:checked::after {
    content: '\2714'; /* Checkmark character */
    color: white;
    display: block;
    font-size: 12px;
    text-align: center;
    line-height: 16px;
}

input[type="checkbox"]:hover {
    border-color: #083A91;
}

input[type="checkbox"]:active {
    background-color: #083A91;
}
