.div-modal-usuario {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.user {
    font-size: 20px;
}

.content-veiculo {
    height: 375px;
    width: 605px;
}

.usuario-data {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.coluna-lancar-veiculo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-self: center;
}