.container-alertas {
  display: grid;
  grid-template-rows: repeat(auto, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;
  align-items: center;
  gap: 50px;
  margin: 60px;
}

@media screen and (max-width: 1280px) {
  .container-alertas {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}


.textos-alerta {
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: start;
  padding: 5px;
}

.card-alerta {
  background: rgba(205, 205, 205, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 520px;
  height: 250px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 5px;
}

.div-data-status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}
