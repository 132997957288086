
.cadastro-embarque {
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.texto-cadastro-embarque {
    color: var(--basics-black, #000);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.icone-info-3 {
    margin-left: 10px;
}
