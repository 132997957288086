.container{
    height: 100vh;
    width: 50vw;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.principal-codigo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.card-cadastro {
    min-width: 55vw;
    height: 100%;
    z-index: 2;
    margin: 15vh calc(100px + 10vw) 0 0;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 15px;
    padding: 20px;
}

.texto-informe {
    text-align: center;
}

.grid-item {
    padding: 0px;
}

.grid-container-cadastro {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    margin-top: 2%;
    width: auto;
}

.imageCad{
    height: 100vh;
    min-width: 40vw;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.decoration{
    color: #0946B8;
    text-decoration: none;
    font-size: 16px;
}

.input-campo {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.2s ease;
}

.input-campo:focus {
    border-color: #0946B8;
}

.input-erro {
    border-color: red; /* Destaque com borda vermelha */
    background-color: #ffe6e6; /* Fundo levemente avermelhado */
}

.input-erro:focus {
    border-color: darkred; /* Realce ao focar no campo inválido */
}

.input-invalido {
    border: 2px solid red !important;
    background-color: #ffe6e6;
}

