.principal-mapa {
    width: 100%;
    height: 96vh;
}

.veiculo-detalhes {
    background-color: #efefef;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.mapa-meso-card {
    position: absolute;
    top: 150px;
    right: 10px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-height: 600px;
    z-index: 10;
    display: flex;
    gap: 12px;
    flex-direction: column;
}

.eventos-card {
    position: absolute;
    top: 150px;
    right: 52px;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 250px;
    z-index: 10;
}

.close-button {
    cursor: pointer;
}

.card-header-veiculos {
    font-weight: bold;
    margin-bottom: 10px;
    text-overflow: ellipsis;
    color: var(--Baby-Blue-Font, #002D45);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    gap: 10px
}

.voltar-card-veiculos {
    position: absolute;
    top: 55px;
    right: 0;
    box-shadow: none;
    max-width: auto;
    z-index: 10;
    display: flex;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
}

.legenda-card-veiculos {
    position: absolute;
    top: 120px;
    right: 50px;
    gap: 20px;
    box-shadow: none;
    max-width: auto;
    z-index: 10;
    display: flex;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
}

.mapa-meso-card h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
}

.mapa-meso-card p {
    font-size: 14px;
    margin-bottom: 5px;
}
