.navbar {
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-inicio {
  display: flex;
 
  flex-direction: row;
  
  gap: 10px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: linear-gradient(260deg, rgba(255, 255, 255, 0.20) 3.11%, rgba(0, 0, 0, 0.02) 48.65%, rgba(255, 255, 255, 0.20) 97.58%);
  mix-blend-mode: luminosity;
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(50px);
}

.title-menu {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  letter-spacing: -0.132px;
}

.navbar-inicio {
  display: flex;
  align-items: center;
  background-color: #6a1b9a;
  width: 50px; 
  height: 40px; 
  overflow: hidden;
  transition: width 0.9s ease; 
  cursor: pointer;
}

.navbar-inicio:hover {
  width: 110px; 
  justify-content: center; 
}

.title-menu-inicio {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 10px;
  letter-spacing: -0.132px;
  opacity: 0;
  white-space: nowrap;
  transition: opacity 0.5s ease; 
}

.navbar-inicio:hover .title-menu-inicio {
  opacity: 1; 
}


.iconeMenu2 {
  width: 45px;
  height: 25px;
  margin-top: 0;
  margin-left: 20px;
  cursor: auto;
}

.navbar-icons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}

.navbar-icons-2 {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
}

.navbar-title {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  padding: 10px;
  cursor: pointer;
}

.navbar-title:hover {
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: linear-gradient(260deg, rgba(255, 255, 255, 0.20) 3.11%, rgba(0, 0, 0, 0.02) 48.65%, rgba(255, 255, 255, 0.20) 97.58%);
  mix-blend-mode: luminosity;
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(50px);
}

.right-icons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 250px;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.separador-menu {
  width: 80px;
  height: 5px;
  border-radius: 4px;
  background: rgba(10, 75, 155, 0.80);
}

.dropdown-content {
  position: absolute;
  display: flex;
  left: 0;
  top: 45px;
  width: auto;
  height: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideDown 0.2s ease-out;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 20px;
}

.dropdown-content-emb {
  position: absolute;
  display: flex;
  left: 0;
  top: 45px;
  width: auto;
  height: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideDown 0.2s ease-out;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  padding: 20px;
}

.dropdown-content-config {
  position: absolute;
  display: flex;
  right: 0;
  top: 45px;
  width: auto;
  height: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideDown 0.2s ease-out;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 10px;
}

.p-p-p {
  color: rgba(0, 0, 0, 0.70);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.132px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.p-p-p:hover {
  background: #ececec;
  transition: 0.5s;
}

.dropdown-content-2 {
  display: flex;
  width: auto;
  min-width: 180px;
  height: 100%;
  justify-content: flex-start;
  border-radius: 5px;
  z-index: 1000;
  animation: slideDown 0.2s ease-out;
  flex-direction: column;
  align-items: start;
  padding: 5px;
}

.dropdown-content-2-linha {
  display: flex;
  width: auto;
  min-width: 180px;
  height: 100%;
  justify-content: flex-start;
  border-radius: 5px;
  z-index: 1000;
  animation: slideDown 0.2s ease-out;
  flex-direction: column;
  align-items: start;
  border-right: 1px dashed rgba(0, 0, 0, 0.10);
  border-left: 1px dashed rgba(0, 0, 0, 0.10);
  background: #FFF;
  padding: 5px;
}

.content-menu-rows {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin-top: 15px;
  height: 100%;
}

.titulo-navbar-icone {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-left: 16px;
}

.titulo-navbar-texto {
  color: rgba(0, 0, 0, 0.90);
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  /* 21px */
  letter-spacing: -0.154px;
}

.config-dropdown {
  position: absolute;
  right: 0;
  top: 40px;
  width: 180px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideDown 0.2s ease-out;
}

.config-dropdown p {
  padding: 10px;
  margin: 0;
  cursor: pointer;
}

.config-dropdown b {
  padding: 10px;
  margin: 0;
}

.config-dropdown p:hover {
  background-color: #f5f5f5;
}

.image-perfil {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ddd;
}

.image-header {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.image-perfil img {
  cursor: pointer;
  width: 100%;
  height: auto;
}

.menu-bars-v2 {
  background: none;
}

.iconeMenu {
  width: 40px;
  height: 28px;
  margin-top: 0;
  cursor: pointer;
}

.navbar-inicio {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: linear-gradient(260deg, rgba(255, 255, 255, 0.20) 3.11%, rgba(0, 0, 0, 0.02) 48.65%, rgba(255, 255, 255, 0.20) 97.58%);
  mix-blend-mode: luminosity;
  box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(50px);
}

.title-menu {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  letter-spacing: -0.132px;
}

.title-menu-inicio {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
  letter-spacing: -0.132px;
  display: none;
}

.navbar-inicio:hover .title-menu-inicio {
  display: block;
  opacity: 1;
  transition: opacity 2s ease, transform 2s ease;
  transition-delay: 1s;
}

.iconeMenu2 {
  width: 45px;
  height: 25px;
  margin-top: 0;
  margin-left: 20px;
  cursor: auto;
}

.iconeX {
  margin-top: 0;
  width: 25px;
  height: 25px;
  margin-left: 20px;
}

.nav-menu {
  width: 270px;
  height: 95vh;
  display: flex;
  justify-content: center;
  position: fixed;
  left: -100%;
  transition: 500ms;
  overflow: scroll;
  z-index: 9999;
}

.diariaBotao {
  width: 35px !important;
  height: 35px !important;
}

.nav-menu.active {
  left: 0;
  transition: 500ms;
  border-right: 1px solid #DBDBDB;
  background: var(--basics-white, #FFF);
}

.icone-selecionado {
  filter: drop-shadow(0px 0px 2px #ffffff);
}

.botao-feed {
  margin-top: 15vh;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255);
  box-shadow: inset 0 0 6px rgb(255, 255, 255);
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgb(37, 37, 37);
  box-shadow: inset 0 0 6px rgb(37, 37, 37);
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px 0px 0px 16px;
  list-style: none;
  height: 50px;
  border-bottom-style: groove;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-width: 1px;
}

.nav-text-comum {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px 0px 0px 16px;
  list-style: none;
  height: 40px;
  border-bottom-style: groove;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-width: 1px;
  padding-left: 30px;
}

.nav-text-comum-2 {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px 0px 0px 16px;
  list-style: none;
  height: 40px;
  border-bottom-style: groove;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-width: 1px;
  padding-left: 45px;
}

.menus-escondidos {
  display: none;
}

.nav-text a {
  text-decoration: none;
  color: #000;
  font-size: 15px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  border-radius: 4px;
}

.nav-text-comum a {
  text-decoration: none;
  color: #000;
  font-size: 15px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text-comum-2 a {
  text-decoration: none;
  color: #000;
  font-size: 15px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-menu-items {
  width: 100%;
}

span.botao-menu {
  margin-left: 16px;
  margin-top: 0px;
  color: rgba(0, 0, 0, 0.70);
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 18px */
  letter-spacing: -0.132px;
}

.css-106c1u2-MuiBadge-badge {
  margin-right: 8px;
}

@media screen and (max-height: 900px) {
  .dropdown-content {
    padding: 2px;
  }
  
  .dropdown-content-2-linha {
    height: 90%;
  }

  .separador-menu {
    position: fixed;
  }

  a {
    height: 30px;
  }

  .content-menu-rows {
    margin-top: 5px;
  }
}