.texto-avisos {
    color: var(--basics-black, #000);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}

.central {
    display: flex;
    align-items: start;
}

.text-card-home {
    color: var(--basics-black, #000);
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 40px;
}

.divImagemV2 {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

}

.container-home {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
}

.container-bem-vindo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    max-width: 860px;
    margin-bottom: 45px;
   
}

.texto-bem-vindo {
    color: var(--basics-black, #000);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.texto-usuario {
    color: var(--basics-black, #000);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.card-home {
    display: grid;
    grid-template-columns: 1fr 35px;
    grid-template-rows: 1fr 75px;
    gap: 10px;
    width: 267px;
    height: 150px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid var(--basics-black, #000);
    background: var(--basics-white, #FFF);
    margin: 12px;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.card-home:hover {
    background-color: #B0C4DE; 
}

.filial-dropdown {
    position: absolute;
    top: 320px;
    right: 1045px;
    width: 150px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;

}
  
.filial-dropdown p {
    padding: 10px;
    margin: 0;
    cursor: pointer;
}

.filial-dropdown p:hover {
    background-color: #f5f5f5;
}

span {
    margin: 0px;
    margin-top: 5px;
}

.card-carrosel-2 {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    max-width: 860px;
    margin: 20px;
    border-radius: 10px;
}

.imagemHomeV2 {
    width: 15%;
    height: 15%;
}

.corpoModalCookies {
    height: 100%;
    outline: 0px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.corpoModalCookies .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    bottom: 0;
    position: fixed !important;
    margin: 0px !important;
    width: 100% !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;

}

.MuiPaper-root {
    max-width: 200vh !important;
}

.texto-link {
    margin-right: 25px;
}

.botaoConcluir {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px;
}


@media screen and (max-width: 450px) {
    .texto-cookies {
        padding: 5%;
    }

    .botaoConcluir {
        padding: 5%;
    }

    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        flex-direction: column !important; 
    }
}

@media screen and (max-width: 1600px) {
    .filial-dropdown {
        top: 320px;
        right: 845px;
    }
}

@media screen and (max-width: 1300px) {
    .filial-dropdown {
        top: 320px;
        right: 645px; 
    }
}

@media screen and (max-width: 1290px), screen and (max-height: 730px) {
    .divImagemV2 {
        margin-top: 1%; 
    }

    .container-bem-vindo {
        padding: 20px;
        max-width: 720px;
        margin-bottom: 20px;
    }

    .card-home {
        gap: 5px; 
        width: 225px; 
        height: 140px; 
        margin: 10px; 
        padding: 10px; 
    }

    .card-carrosel-2 {
        max-width: 720px;
        margin: 20px;
    }
}

@media screen and (max-width: 768px) {
    .container-home, .container-bem-vindo {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .imagemHomeV2 {
        width: 30%; /* Aumente o tamanho proporcionalmente */
        height: 30%;
    }

    .texto-bem-vindo, .texto-usuario {
        font-size: 20px; 
        text-align: center; 
    }

    .card-home {
        width: 100%; 
        max-width: 320px; 
        height: auto; 
        margin: 10px 0;
        padding: 15px;
        grid-template-rows: auto;
    }

    .text-card-home {
        margin-left: 0; 
        font-size: 18px; 
        text-align: center;
    }

    .filial-dropdown {
        top: auto; 
        right: 20px;
        left: 20px;
        width: auto; 
    }

    .divImagemV2 {
        margin-top: 2%; 
    }

    .card-carrosel-2 {
        max-width: 100%;
        margin: 10px;
    }
}
