.div-filtrar-pedido {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 420px;
    margin: -18px;
    margin-left: 5%;
}

.div-filtrar-cliente {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 420px;
    margin-left: 32%;
}

@media screen and (max-width: 700px) {
    .div-filtrar-cliente {
        margin-left: 5%;
    }
}

.div-filtrar-veiculo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 420px;
    margin: -40px;
    margin-left: 58%;
}

.div-filtrar-embarque {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 660px;
    margin: -18px;
    margin-left: 30px;
}

.div-filtrar-dash {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 820px;
    margin: -18px;
    margin-left: 30px;
}

.div-filtrar-faturamento {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    background: #DFDFDF;
    border-radius: 5px;
    width: 820px;
    margin: -18px;
    margin-top: -40px;
    margin-left: 30px;
}

@media screen and (max-width: 800px) {
    .div-filtrar-embarque {
        flex-direction: column;
        width: auto;
        align-items: start;
        justify-content: start;
    }
}