.div-modal-lista-erros {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    max-height: 500px;
}

.delete {
    font-size: 20px;
}