.loading-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    margin-top: 12%;
}

.carregando-texto {
    font-family: OCR A Std, monospace;
    font-size: 18px;
}

@media screen and (max-width: 600px) {
    .loading-component {
        height: 30em;
    }
}