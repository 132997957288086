.carrossel-vertical-painel {
  width: 100%;
  max-width: 100%;
}

.teste-painel
.slick-list {
  height: 84vh !important;
}

.bolinhas {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: end;
  width: 100%;
}

.bolinha-vermelha {
  width: 16px;
  height: 16px;
  background-color: rgb(226, 15, 15);
  border-radius: 50%;
  margin: 0 5px;
}

.bolinha-verde {
  width: 16px;
  height: 16px;
  background-color: rgb(0, 139, 19);
  border-radius: 50%;
  margin: 0 5px;
}

.painel-card {
  margin: 10px 0 10px 0;
  text-align: start;
  padding: 23px 40px;
  justify-content: start;
  align-items: center;
  display: flex !important;
  flex-direction: column !important;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #C3D8E3;
  background: #E8F7FF;
}

.painel-card p {
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  color: #333;
}

.slick-track {
  height: 280px !important;
}

.carrossel-vertical .slick-dots {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  display: flex !important;
  flex-direction: column;
}

.carrossel-vertical .slick-dots li {
  margin: 5px 0;  /* ou qualquer outro valor para ajustar o espaçamento */
}

.carrossel-vertical .slick-dots li button {
  width: 10px;   /* ajuste conforme necessário */
  height: 10px;  /* ajuste conforme necessário */
  border-radius: 50%; 
}

.carrossel-vertical .slick-dots li button:before {
  font-size: 10px;  /* ajuste conforme necessário */
  line-height: 10px;  /* ajuste conforme necessário */
}
