.div-modal-status-manual {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 500px;
    height: 500px;
}

.user {
    font-size: 20px;
}

.usuario-data {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}