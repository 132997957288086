.botoesModal {
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center;
}

.MuiPaper-root {
    max-width: 150vh !important;
}

.titulo {
    color: black;
    display: flex;
    justify-content: center;
}