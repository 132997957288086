.principal-diariaVeiculo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2% auto 0;
  box-shadow: 5px 10px 18px #888888;
  border-radius: 30px;
  width: 100%;

}

.diariaVeiculo-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid-container-diariaVeiculo {
  display: grid;
  width: 100%;
  grid-template-columns: auto auto auto;
  padding: 30px;
}

.grid-item-diariaVeiculo {
  padding: 20px;
  text-align: center;
  margin: 0.5em;
}

.MuiTextField-root {
  border: groove !important;
}

.titulo-select-cadastro {
  cursor: pointer;
}

.decoration {
  text-decoration: underline
}

.botoes-diariaVeiculo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 5%;
}

.input-text {
  width: 250px;
  padding: 20px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: groove !important;
  font-size: 16px;
}

.botoes-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.linha-selecionada {
  background-color: #cacaca !important; /* Cor de destaque para a linha selecionada */
  font-weight: bold;
}

.indicadores {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.indicador-principal {
  color: #4F4F4F;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.indicador-principal-2 {
  color: #989898;
}

.indicador-secundario {
  color: #4F4F4F;
  margin-left: 10px;
}

.tr-hover:hover {
  cursor: pointer;
}

.tabela-diaria-veiculo {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  border-left: 1px solid var(--Border, #c2c2c2);
  border-right: 1px solid var(--Border, #c2c2c2);
  border-bottom: 1px solid var(--Border, #c2c2c2);
  background: var(--ColorImput, #fafafa);
  /* border-collapse: collapse; */
}

.th-diaria-veiculo {
  width: 150px;
  text-align: center;
  color: var(--Basics-black, #000);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 12px !important;
  border-bottom: 1px solid var(--Border, #c2c2c2);
  border-top: 1px solid var(--Border, #c2c2c2);
  background: var(--ColorImput, #fafafa);
  position: sticky;
  top: 0px;
  cursor: pointer;
  z-index: 2;
  /* Garante que o cabeçalho fique acima dos outros elementos */
}

.numero-embarques-diaria-veiculo {
  position: sticky;
  top: -2px;
  z-index: 1;
}

.td-diaria-veiculo {
  text-align: center;
  color: var(--Basics-black, #000);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 3px !important;
  border-bottom: 1px solid var(--Border, #c2c2c2);
  background: var(--ColorImput, #fff);
}

.outer-wrapper-diaria-veiculo {
  border-radius: 4px;
  max-width: 100%;
  margin: 0px 20px;
  max-height: fit-content;
}

.table-wrapper-diaria-veiculo {
  overflow-y: auto;
  overflow-x: auto;
  max-width: 100%;
  height: fit-content;
  max-height: 75vh;
  margin-top: 22px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.td-border-bottom {
  border-bottom: 1px solid var(--Border, #c2c2c2);
}

.info-div {
  display: flex;
  flex-direction: column;
}

.input-diaria-veiculo {
  border-radius: 7px;
  background: #f6f6f6;
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.25);
  height: 30px;
  border: none;
  padding: 10px;
}

.row-diaria-veiculo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.title-diaria-veiculo {
  color: var(--Basics-black, #000);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.config-dropdown-diaria-veiculo {
  position: absolute;
  gap: 10px;
  right: -55px;
  bottom: -225px;
  z-index: 1000;
  animation: slideDown 0.3s ease-out;
}

table td {
  padding: 0px;
}


@media screen and (max-width: 1200px) {
  .grid-container-diariaVeiculo {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
  }
}

@media screen and (max-width: 600px) {
  .botoes-footer {
    display: grid;
    grid-template-columns: 170px 150px;
  }
}

@media screen and (max-width: 750px) {
  .grid-container-diariaVeiculo {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}

@media screen and (max-height: 800px) {
  .botoes-diariaVeiculo {
    margin-top: 8%;
  }

  .principal {
    margin-top: 8%;
  }
}