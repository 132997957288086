.div-modal-notificacao {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.user {
    font-size: 20px;
}

.texto-notificacao {
    text-align: center;
}

.notificacao-data {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icone-notificacao {
    width: 2em !important;
    height: 2em !important;
}