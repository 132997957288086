.div-modal-usuario {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.user {
    font-size: 20px;
}

.usuario-data-row {
    flex-direction: row;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    gap: 20px;
}

.content-pagamento {
    width: 500px;
    height: 375px;
}