.image-login {
    width: 85vh;
    height: 100%;
}

/* .lock-icon {
    margin-left: 8px;
} */

.esqueci-senha .lock-icon {
    background-image: url('../../../images/iconLock2.png');
    background-size: cover;
    margin-left: 8px;
    display: inline-block;
}

.esqueci-senha:hover .lock-icon {
    background-image: url('../../../images/iconLock.png');
    margin-left: 8px;
}

.container-login {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    overflow-y: none !important;
    margin-top: 0px;
}

.label-login {
    color: #121212;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.title-login {
    font-size: 64px;
    font-weight: 700;
    line-height: 77px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 60px;
}

.botao-entrar {
    display: inline-flex;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #0946B8;
    width: 140px;
    height: 45px;
    border: none
}

.botao-entrar-disabled {
    display: inline-flex;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: #aaaaaa;
    width: 140px;
    height: 45px;
    border: none
}

.botao-entrar:hover {
    background: #003393;
    cursor: pointer;
    transition: 300ms;
}

.input-login {
    width: 407px;
    height: 65px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #E4E4E4;
    background: linear-gradient(180deg, #F2F2F2 0%, #F8F8F8 100%);
    padding: 15px;
    font-size: 20px;
}

.input-login:hover {
    border: 1px solid #000000;
    transition: 300ms;
    border-radius: 10px;
}

.input-login:focus {
    border-color: #0946B8;  /* Altere 'blue' para a cor que você deseja */
    outline: none; 
    border-radius: 10px;      /* Isso remove a borda padrão que os navegadores aplicam */
}

select:hover {
    border-radius: 10px;
}

.card-login {
    position: relative;
    margin-left: 15%;
    width: 407px;
    height: 544px;
}

.input {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    width: 100%;
}

.botao {
    margin-top: 4vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 2vh;
}

.cadastrese {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0;
}

.esqueci-senha {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 25px;
}

.text-entrar {
    color: var(--basics-white, #FFF);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

a {
    font-size: 14px;
}

.p-card-login-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 1400px){
    .card-login {
        width: 407px;
        height: 544px;
    }
}

@media screen and (max-width: 1700px) {
    .card-login {
        margin-left: 0;
    }
}

@media screen and (max-width: 900px), screen and (max-height: 550px) {
    .image-login {
        display: none;
    }

    .container-login {
        justify-content: center;
    }
}

@media screen and (max-width: 1400px) {
    .title-login {
        font-size: 44px;
    }

    .label-login {
        font-size: 18px;
    }

    .input-login {
        width: 340px;
        height: 54px;
    }

    .card-login {
        width: 340px;
    }

    .botao-entrar {
        width: 130px;
        height: 42px;
    }
}
