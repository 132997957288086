.div-buscas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    margin-bottom: 25px;
    width: auto;
}

.click-configurar {
    cursor: pointer;
}

.butao {
    display: flex;
    justify-content: center;
}

.icone {
    display: flex;
    flex-direction: row;
}

.checkcheck {
    margin-left: 0px !important;
}

@media screen and (max-width: 600px) {
    .butao {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .css-hlj6pa-MuiDialogActions-root>:not(:first-of-type) {
        margin-left: 0px !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}