.div-buscas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
}

.click-configurar {
    cursor: pointer;
}

.butao {
    display: flex;
    justify-content: center;
}

.icone {
    display: flex;
    flex-direction: row;
}