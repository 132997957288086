.div-modal-usuario {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.user {
    font-size: 20px;
}

.usuario-data {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fixed {
    position: fixed;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}