.custom-button {
    width: 180px;
}

.custom-button-add {
    width: 200px;
}

.custom-button-margin {
    width: 180px;
    margin-left: 20px !important;
}

.custom-button-margin-pequeno {
    width: auto;
    margin-left: 20px !important;
}

.custom-button-margin-giant {
    width: 250px;
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
}

.custom-button-cancelar {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background: var(--seconday-backbround, #E0E0E0);
}

.custom-button-entrega {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background: var(--seconday-backbround, #E0E0E0);
}

.custom-button-cadastrar {
    border-radius: 10px;
    width: 144px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #0946B8;
    border: none;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-cadastrar:hover {
    background: #003393;
}

.custom-button-cadastrar-red {
    border-radius: 10px;
    width: 144px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #DC2D2D;
    border: none;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-cadastrar-red:hover {
    background: #DC2D2D;
}

.custom-button-cadastrar-gray {
    border-radius: 10px;
    width: 144px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #858585;
    border: none;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-cadastrar-gray:hover {
    background: #858585;
}

.custom-button-cadastrar-disabled {
    border-radius: 10px;
    width: 144px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #858585;
    border: none;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-cadastrar-cock {
    border-radius: 10px;
    width: 205px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #0946B8;
    border: none;
    margin: 0 20px;
    cursor: pointer;
}

.custom-button-editar {
    border-radius: 10px;
    width: 113px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #CDCDCD;
    border: none;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-premios {
    border-radius: 10px;
    width: 133px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #CDCDCD;
    border: none;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-deletar {
    border-radius: 10px;
    width: 124px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #DC2D2D;
    border: none;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-user {
    width: 146px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #0946B8;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-mercosul {
    width: 200px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #0946B8;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-vincular {
    width: 166px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #0946B8;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-proposta {
    width: 156px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #0946B8;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-mapa {
    width: 200px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #0946B8;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-geometria {
    width: 100px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #0946B8;
    margin: 0 5px;
    cursor: pointer;
}

.custom-button-faturamento {
    width: 160px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 20px;
    border: 1px solid #0946B8;
    margin: 0 5px;
    cursor: pointer;
}

.button-texto-faturamento {
    color: #0946B8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.custom-button-historico {
    width: 108px;
    display: inline-flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #0946B8;
    margin: 0 5px;
    cursor: pointer;
}

.button-texto-cancelar {
    color: var(--secondary-label, #262626);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button-texto-entrega {
    color: var(--secondary-label, #262626);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button-texto-cadastrar {
    color: var(--basics-white, #FFF);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button-texto-editar {
    color: var(--basics-black, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.button-texto-user {
    color: #0946B8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.custom-button-giant {
    width: 650px;
}

@media screen and (max-width: 1550px) {
    .custom-button-margin-giant {
        margin-bottom: 15px !important;
        margin-top: 15px !important;
    }
}

@media screen and (max-width: 670px) {
    .custom-button-giant {
        width: 250px;
    }
}

@media screen and (max-width: 600px) {
    .custom-button-margin {
        width: 150px;
        margin-left: 0px !important;
        padding: 5px !important;
        margin-bottom: 10px !important;
    }
}
