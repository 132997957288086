.div-modal-legenda {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
}

.legendas {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.legenda-item {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 5px;
}

.legenda-cor {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #000;
    margin: 0;
}

.custom-button-ok {
    margin-top: 10px;
}
