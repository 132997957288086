.principal-frotas {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.indicadores-frota {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.indicador-principal-frota {
    color: var(--basics-black, #000);
    padding: 16px 130px 16px 105px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
