.principal-select-estado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.select-estado {
    border: groove !important;
    padding: 12px 20px !important;
    margin: 8px 0 !important;
    box-sizing: border-box !important;
    background-color: white;
}