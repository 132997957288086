.containerEdita {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
}

.principal-edit-user {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    margin-top: 5%;
    gap: 10px;
}

.form {
    margin-top: 3vh;
}

.input-text-emailsenha {
    width: 350px;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3vh;
}

.botoes-edit-user {
    margin-top: 3vh;
    gap: 45px;
}

@media screen and (max-width: 700px) {
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
    }

    .botoes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        margin-top: 3vh;
    }
}
