.principal-caminhao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2% auto 0;
    box-shadow: 5px 10px 18px #888888;
    border-radius: 30px;
    width: 100%;

}

.caminhao-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.grid-container-caminhao {
    display: grid;
    width: 100%;
    grid-template-columns: auto auto auto auto;
    padding: 30px;
}

.grid-container-caminhao-2 {
    display: grid;
    width: 100%;
    grid-template-columns: auto auto auto;
    padding: 0 30px;
}

.grid-item {
    padding: 20px;
    font-size: 30px;
    text-align: center;
    margin: 0.5em;
}


.MuiTextField-root {
    border: groove !important;
}

.titulo-select-cadastro {
    cursor: pointer;
}

.decoration {
    text-decoration: underline
}

.botoes-caminhao {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5%;
}

.input-text {
    width: 250px;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.botoes-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.indicadores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.indicador-principal {
    color: #4F4F4F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.indicador-principal-2 {
    color: #989898;
}

.indicador-secundario {
    color: #4F4F4F;
    margin-left: 10px;
}

@media screen and (max-width: 1200px) {
    .grid-container-caminhao {
        display: grid;
        grid-template-columns: auto auto;
        padding: 10px;
    }
}

@media screen and (max-width: 600px) {
    .botoes-footer {
        display: grid;
        grid-template-columns: 170px 150px;
    }
}

@media screen and (max-width: 750px) {
    .grid-container-caminhao {
        display: flex;
        flex-direction: column;
        width: auto;
    }

    .grid-container-caminhao-2 {
        display: flex;
        flex-direction: column;
        width: auto;
    }
}

@media screen and (max-height: 800px) {
    .botoes-caminhao {
        margin-top: 8%;
    }
    .principal {
        margin-top: 8%;
    }
}