.containerBuscas {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.opcoes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
    gap: 2vh;
    margin-top: 2vh;
}

.botaoDash {
    display: flex;
    margin-top: 2vh;
    gap: 2vh;
}

.graficosCima {
    display: grid;
    grid-template-columns: auto auto auto;
    flex-direction: row;
    justify-content: center;
    gap: 5vh;
    margin-top: 1vh;
}

.graficosBaixo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.graficosBaixoCima {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vh;
    margin-top: 2vh;
}

.graficosBaixoBaixo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vh;
    margin-top: 2vh;
}

.item {
    width: 35em;
}

@media screen and (max-width: 1400px) {
    .graficosCima {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
    }
}

@media screen and (max-width: 1000px) {
    .graficosCima {
        display: grid;
        grid-template-columns: auto;
        align-items: center;
    }

    .opcoes {
        display: flex;
        flex-direction: column;
    }

    .graficosBaixo {
        display: flex;
        flex-direction: column;
    }

    .graficosBaixoCima {
        display: flex;
        flex-direction: column;
    }

    .graficosBaixoBaixo {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {
    .botaoDash {
        flex-direction: column !important;
    }

    .item {
        width: 25em;
    }
}

@media screen and (max-width: 450px) {
    .item {
        width: 17em;
    }
}

.indicador-principal {
    color: #4F4F4F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.indicador-principal-2 {
    color: #989898;
}

.paginacao {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 2vh;
}

@media print {
    .containerBuscas {
        display: none;
    }

    .menu-bars {
        display: none;
    }

    .navbar {
        background-color: rgb(1 1 1 / 0%) !important;
    }

    .indicadores {
        margin-top: -4vh;
    }

    .p-card {
        margin-bottom: -3vh;
    }

    .paginacao {
        display: none;
    }

    .graficosCima {
        display: grid;
        grid-template-columns: auto;
        align-items: center;
        margin-top: 100px;
    }

    .graficosBaixo {
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
    }
}