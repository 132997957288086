.botoes-cores {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3%;
    background-color: rgba(124, 124, 124, 0.4);
    border-radius: 10px;
    padding: 50px;
}

.corpo-cores {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titulo-configs {
    margin-top: 2%;
    margin-bottom: 2%;
}

@media screen and (max-width: 1550px) {
    .botoes-cores {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 3%;
    }
}