.container-edita {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
}

.form {
    margin-top: 3vh;
}

.input-text {
    width: 250px;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.input-text-senha {
    width: 250px;
    padding: 13px 15px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
    background: white;
}

.text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3vh;
}

.botoes {
    margin-top: 3vh;
}

@media screen and (max-width: 850px) {
    .container-edita {
        flex-direction: column;
    }
}

@media screen and (max-width: 700px) {
    .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
    }

    .botoes {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-self: center;
        margin-top: 3vh;
    }
}
