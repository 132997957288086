.custom-button {
    width: 180px;
}

.custom-button-voltar {
    width: 70px;
}

.custom-button-meso {
    width: 170px;
    margin-left: 10px !important;
}

.custom-button-add {
    width: 200px;
}

.custom-button-margin {
    width: 180px;
    margin-left: 20px !important;
}

.custom-button-margin-pequeno {
    width: auto;
    margin-left: 20px !important;
}

.custom-button-margin-giant {
    width: 250px;
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-bottom: 15px !important;
    margin-top: 15px !important;
}

.custom-button-giant {
    width: 650px;
}

@media screen and (max-width: 1550px) {
    .custom-button-margin-giant {
        margin-bottom: 15px !important;
        margin-top: 15px !important;
    }
}

@media screen and (max-width: 670px) {
    .custom-button-giant {
        width: 250px;
    }
}

@media screen and (max-width: 600px) {
    .custom-button-margin {
        width: 150px;
        margin-left: 0px !important;
        padding: 5px !important;
        margin-bottom: 10px !important;
    }
}
