.principal-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0;
}

.select {
    border: groove !important;
    padding: 12px 20px !important;
    margin: 8px 0 !important;
    box-sizing: border-box !important;
    background-color: white;
}

.select-pequeno {
    border: groove !important;
    padding: 5px 20px !important;
    margin: 0px 0 !important;
    box-sizing: border-box !important;
    background-color: white;
}

.info-icon {
    position: absolute;
    right: 20px;
}

.select-emb-2 {
    display: flex;
    height: 35px;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    border-radius: 7px;
    border: 1px solid var(--Background-Input, #F2F2F2);
    background: var(--Background-Input, #F2F2F2);
}