.indicadores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.indicador-principal-motorista {
    color: #4F4F4F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.botoes-pedido {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 4%;
    margin-bottom: 5%;
}

.input-text1 {
    width: 800px;
    line-break: anywhere;
    padding: 20px 20px;
    margin: 8px 0 50px;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.input-text-pedido {
    width: 250px;
    padding: 12px 12px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.grid-container-pedido {
    display: grid;
    width: 100%;
    grid-template-columns: auto auto auto auto;
    padding: 30px;
}

.grid-item-pedido {
    padding: 20px;
    text-align: center;
}

.grid-item-select-pedido {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.titulo-select-cadastro {
    text-align: center;
}

.grid-item-embutido {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    margin: auto;
}

.indicador-principal-2 {
    color: #989898;
}

.indicador-secundario {
    color: #4F4F4F;
    margin-left: 10px;
}

.container-botoes-emb {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
}

@media screen and (max-width: 2100px) {
    .box {
        display: table-column;
    }
}

@media screen and (max-width: 1200px) {
    .grid-container-veiculo {
        display: grid;
        grid-template-columns: auto auto;
        padding: 10px;
    }

    .div-filtrar-pedido {
        margin: -10px;
        margin-left: 15%;
    }
}

@media screen and (max-width: 750px) {
    .grid-container-veiculo {
        display: flex;
        flex-direction: column;
        width: auto;
    }
}

.boxName {
    display: flex;
    flex-direction: row;
}