.tabela-ocorrencias {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  border-left: 1px solid var(--Border, #c2c2c2);
  border-right: 1px solid var(--Border, #c2c2c2);
  border-bottom: 1px solid var(--Border, #c2c2c2);
  background: var(--ColorImput, #fafafa);
  /* border-collapse: collapse; */
}

.th-ocorrencias {
  text-align: center;
  color: var(--Basics-black, #000);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 12px !important;
  border-bottom: 1px solid var(--Border, #c2c2c2);
  border-top: 1px solid var(--Border, #c2c2c2);
  background: var(--ColorImput, #fafafa);
  position: sticky;
  top: 0px;
  cursor: pointer;
  z-index: 2; /* Garante que o cabeçalho fique acima dos outros elementos */
}

.numero-embarques-ocorrencias {
  position: sticky;
  top: -2px;
  z-index: 1; 
}

.td-ocorrencias {
  text-align: center;
  color: var(--Basics-black, #000);
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 3px !important;
  border-bottom: 1px solid var(--Border, #c2c2c2);
  background: var(--ColorImput, #fff);
}

.outer-wrapper-ocorrencias {
  border-radius: 4px;
  max-width: 100%;
  margin: 0px 20px;
  max-height: fit-content;
}

.table-wrapper-ocorrencias {
  overflow-y: auto;
  overflow-x: auto;
  max-width: 100%;
  height: fit-content;
  max-height: 80vh;
  margin-top: 22px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.td-border-bottom {
  border-bottom: 1px solid var(--Border, #c2c2c2);
}

.info-div {
  display: flex;
  flex-direction: column;
}

.input-ocorrencias {
  border-radius: 7px;
  background: #f6f6f6;
  box-shadow: 2px 2px 7px 0px rgba(0, 0, 0, 0.25);
  height: 30px;
  border: none;
  padding: 10px;
}

.row-ocorrencias {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.title-ocorrencias {
  color: var(--Basics-black, #000);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
}

.config-dropdown-ocorrencias {
  position: absolute;
  gap: 10px;
  right: -55px;
  bottom: -225px;
  z-index: 1000;
  animation: slideDown 0.3s ease-out;
}

table td {
  padding: 0px;
}
