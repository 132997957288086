.div-modal-vincular {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.content-vincular {
    height: 375px;
    width: 800px;
}

.action-vincular {
    margin-top: 10px;
}

.delete {
    font-size: 20px;
}

.coluna-modalmotivo {
    display: flex;
    align-items: flex-end;
    gap: 2px;
    justify-content: center;
    margin-top: 15px;
}