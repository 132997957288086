.principal-select {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.select {
    border: groove !important;
    padding: 12px 20px !important;
    margin: 8px 0 !important;
    box-sizing: border-box !important;
    background-color: white;
}

.select-pequeno {
    border: groove !important;
    padding: 5px 20px !important;
    margin: 0px 0 !important;
    box-sizing: border-box !important;
    background-color: white;
}

.info-icon {
    position: absolute;
    right: 20px;
}