.containerCadastra {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
}

.text {
    margin-bottom: 5vh;
}

.containerPermissao {
    margin-top: 2vh;
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}