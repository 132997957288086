.principal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 12%;
}

.MuiTextField-root {
    border: groove !important;
}

.coluna-sorteio {
    display: flex;
    align-items: end;
    gap: 2px;
    flex-shrink: 0;
    gap: 20px;
}

.container-cupom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.cupom {
    border: 2px dashed #ccc;
    padding: 20px;
    margin: 20px 0;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Courier New', Courier, monospace;
}

.cupom h5 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
}

.cupom p {
    margin: 5px 0;
    font-size: 1em;
    color: #666;
}


.config-dropdown-sorteio2 {
    position: absolute;
    height: 185px;
    overflow: auto;
    bottom: 50px;
    width: 180px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: slideDown 0.3s ease-out;
}

.config-dropdown-sorteio2 p {
    padding: 10px;
    margin: 0;
    cursor: pointer;
}

.config-dropdown-sorteio {
    position: absolute;
    bottom: 50px;
    width: 180px;
    background-color: #dc2d2d;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: slideDown 0.3s ease-out;
}

.config-dropdown-sorteio p {
    padding: 10px;
    margin: 0;
    color: white;
    cursor: pointer;
}

.botoes-sorteio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center !important;
    margin-top: 8% !important;
}

.input-text {
    width: 250px;
    padding: 20px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: groove !important;
    font-size: 16px;
}

.botoes-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
}

.indicadores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px;
}

.indicador-principal {
    color: #4F4F4F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.indicador-principal-2 {
    color: #989898;
}

.indicador-secundario {
    color: #4F4F4F;
    margin-left: 10px;
}

@media screen and (max-width: 600px) {
    .botoes-footer {
        display: grid;
        grid-template-columns: 170px 150px;
    }
}


@media screen and (max-height: 800px) {
    .botoes-sorteio {
        margin-top: 8%;
    }

    .principal {
        margin-top: 8%;
    }
}