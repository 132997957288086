.principal-embarque {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.config-dropdown-home {
  position: absolute;
  right: -12px;
  height: 250px;
  overflow: auto;
  bottom: 50px;
  width: 180px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideDown 0.3s ease-out;
}

.config-dropdown-low2 {
  position: absolute;
  height: 250px;
  overflow: auto;
  top: 0px;
  width: 180px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideDown 0.3s ease-out;
}

.config-dropdown-none {
  position: absolute;
  right: -12px;
  bottom: 50px;
  width: 180px;
  background-color: #dc2d2d;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: slideDown 0.3s ease-out;
}

.config-dropdown-home p {
  padding: 10px;
  margin: 0;
  cursor: pointer;
}

.config-dropdown-low2 p {
  padding: 10px;
  margin: 0;
  cursor: pointer;
}

.config-dropdown-none p {
  padding: 10px;
  margin: 0;
  color: white;
  cursor: pointer;
}

.indicador-emb {
  color: var(--basics-black, #000);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 5px;
}

.numero-embarques {
  z-index: 2;
  position: relative;
}

.flex-emb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.embarques {
  display: flex;
  flex-direction: column;
}

.container-botoes-emb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.botoes-mes {
  cursor: pointer;
  border-radius: 35px;
  border: 1px solid var(--Border, #c2c2c2);
  background: var(--basics-white, #fff);
}

.mes-atual {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  fill: var(--basics-black, #000);
}

.grid-container-motorista {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  padding: 10px;
}

.filtro-embarque-v2 {
  display: flex;
  padding: 15px 40px 0 30px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.header-embarque-v2 {
  display: flex;
  padding: 16px 40px 0 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.grid-item-motorista {
  padding: 0px 20px 0px 20px;
  font-size: 30px;
  text-align: center;
}

.grid-item-motorista-titulo {
  padding: 0px 20px 0px 20px;
  font-size: 30px;
  text-align: center;
  margin-bottom: 10px;
}

.texto-mes {
  text-align: center;
  margin-left: 10px;
  margin-right: 30px;
  background: #dfdfdf;
  padding: 5px;
  border-radius: 5px;
}

.texto-periodo {
  text-align: center;
  margin-left: 10px;
}

.check-periodo {
  margin-left: 15px;
}

.MuiTextField-root {
  border: groove !important;
}

.botoes-embarque {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2%;
}

.input-text {
  width: 250px;
  padding: 20px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border: groove !important;
  font-size: 16px;
}

.botoes-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.botoes-header {
  position: fixed;
  width: auto;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  /* margin-top: 10px; */
  margin-left: 680px;
}

.indicadores {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.indicador-principal-embarque-v2 {
  color: var(--basics-black, #000);
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.indicador-principal-2 {
  color: #989898;
}

.indicador-secundario {
  color: #4f4f4f;
  margin-left: 10px;
}

.dados-embarque {
  margin-top: 50px;
  margin-left: 50px;
  color: black !important;
  border: groove;
  width: 150px;
}

.dados-pagamento {
  margin-top: 50px;
  margin-left: -120px;
  color: black !important;
  border: groove;
  width: 200px;
}

.dados-vazio {
  margin-top: 50px;
}

.parent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-column-gap: 20px;
  padding: 20px;
}

.css-9npbnl-MuiFormLabel-root-MuiInputLabel-root {
  color: black !important;
}

.div1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 1 / 10 / 2;
  background-color: rgba(124, 124, 124, 0.4);
  padding: 20px;
  border-radius: 20px;
}

.div2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 1 / 10 / 2;
  background-color: rgba(124, 124, 124, 0.4);
  padding: 20px;
  border-radius: 20px;
  grid-area: 1 / 2 / 10 / 3;
}

.div3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 1 / 10 / 2;
  background-color: rgba(124, 124, 124, 0.4);
  padding: 20px;
  border-radius: 20px;
  grid-area: 1 / 3 / 10 / 4;
}

.div4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 1 / 10 / 2;
  background-color: rgba(124, 124, 124, 0.4);
  padding: 20px;
  border-radius: 20px;
  grid-area: 1 / 4 / 10 / 5;
}

.div5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 1 / 10 / 2;
  background-color: rgba(124, 124, 124, 0.4);
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  grid-area: 1 / 5 / 3 / 7;
}

.div6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 1 / 10 / 2;
  background-color: rgba(124, 124, 124, 0.4);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  grid-area: 3 / 5 / 5 / 7;
}

.div7 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 1 / 10 / 2;
  background-color: rgba(124, 124, 124, 0.4);
  padding: 20px;
  border-radius: 20px;
  grid-area: 5 / 5 / 7 / 7;
}

.div8 {
  grid-area: 1 / 1 / 2 / 2;
  color: black !important;
  border: groove;
}

.div9 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 1 / 1 / 2;
}

.div10 {
  grid-area: 2 / 1 / 2 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div11 {
  grid-area: 3 / 1 / 3 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div12 {
  grid-area: 4 / 1 / 4 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div13 {
  grid-area: 5 / 1 / 5 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div14 {
  grid-area: 6 / 1 / 6 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div15 {
  grid-area: 7 / 1 / 7 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div16 {
  grid-area: 8 / 1 / 8 / 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div17 {
  color: black !important;
  border: groove;
  grid-area: 1 / 2 / 2 / 3;
}

.div18 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 2 / 1 / 3;
}

.div19 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 2 / 2 / 2 / 3;
}

.div20 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 3 / 2 / 3 / 3;
}

.div21 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 4 / 2 / 4 / 3;
}

.div22 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 5 / 2 / 5 / 3;
}

.div23 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 6 / 2 / 6 / 3;
}

.div24 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 7 / 2 / 7 / 3;
}

.div25 {
  color: black !important;
  border: groove;
  grid-area: 1 / 3 / 2 / 4;
}

.div26 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 3 / 1 / 4;
}

.div27 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 2 / 3 / 2 / 4;
}

.div28 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 3 / 3 / 3 / 4;
}

.div29 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 4 / 3 / 4 / 4;
}

.div30 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 5 / 3 / 5 / 4;
}

.divAMoto {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 6 / 3 / 6 / 4;
}

.div31 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 7 / 3 / 7 / 4;
}

.div32 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 8 / 3 / 8 / 4;
}

.divSaldo {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 9 / 3 / 9 / 4;
}

.div33 {
  color: black !important;
  border: groove;
  grid-area: 1 / 4 / 2 / 5;
}

.div34 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 4 / 1 / 5;
}

.div35 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 2 / 4 / 2 / 5;
}

.div36 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 3 / 4 / 3 / 5;
}

.div37 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 4 / 4 / 4 / 5;
}

.div38 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 5 / 4 / 5 / 5;
}

.div39 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 6 / 4 / 6 / 5;
}

.div40 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 7 / 4 / 7 / 5;
}

.divMotivo {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 8 / 4 / 8 / 5;
}

.divCheck {
  grid-area: 9 / 4 / 9 / 5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div41 {
  color: black !important;
  border: groove;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 5 / 2 / 6;
}

.div42 {
  color: black !important;
  border: groove;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 6 / 2 / 7;
}

.div43 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 5 / 2 / 6;
}

.div44 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 1 / 6 / 2 / 7;
}

.div45 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 2 / 5 / 2 / 6;
}

.div46 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 2 / 6 / 2 / 7;
}

.div60 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 3 / 5 / 3 / 6;
}

.div61 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 4 / 5 / 7 / 6;
}

.div62 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 3 / 6 / 3 / 6;
}

.div63 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 4 / 6 / 7 / 6;
}

.div47 {
  color: black !important;
  border: groove;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 4 / 5 / 5 / 6;
}

.div48 {
  color: black !important;
  border: groove;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 4 / 6 / 5 / 7;
}

.div49 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 3 / 5 / 4 / 6;
}

.div50 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 3 / 6 / 4 / 7;
}

.div51 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 4 / 5 / 5 / 6;
}

.div52 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 4 / 6 / 5 / 7;
}

.div53 {
  color: black !important;
  border: groove;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 7 / 5 / 8 / 7;
}

.div54 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 5 / 5 / 6 / 7;
}

.div55 {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-area: 6 / 5 / 7 / 7;
}

@media screen and (max-width: 600px) {
  .botoes-footer {
    display: grid;
    grid-template-columns: 170px 150px;
  }
}

@media screen and (max-width: 750px) {
  .grid-container-motorista {
    display: flex;
    flex-direction: column;
    width: auto;
  }
}

@media screen and (max-width: 1800px) {
  .grid-container-motorista {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
  }

  .grid-item-motorista-titulo {
    display: none !important;
  }

  .parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(12, 1fr);
    grid-column-gap: 20px;
  }

  .div1 {
    margin-bottom: 20px;
    grid-area: 1 / 1 / 9 / 2;
  }

  .div2 {
    margin-bottom: 20px;
    grid-area: 1 / 2 / 9 / 3;
  }

  .div3 {
    margin-bottom: 20px;
    grid-area: 1 / 3 / 10 / 4;
  }

  .div4 {
    margin-bottom: 20px;
    grid-area: 1 / 4 / 10 / 5;
  }

  .div5 {
    grid-area: 9 / 1 / 11 / 3;
  }

  .div6 {
    grid-area: 10 / 3 / 13 / 5;
  }

  .div7 {
    grid-area: 11 / 3 / 13 / 1;
  }

  .div9 {
    grid-area: 1 / 1 / 1 / 2;
  }

  .div10 {
    grid-area: 2 / 1 / 2 / 2;
  }

  .div11 {
    grid-area: 3 / 1 / 3 / 2;
  }

  .div12 {
    grid-area: 4 / 1 / 4 / 2;
  }

  .div13 {
    grid-area: 5 / 1 / 5 / 2;
  }

  .div14 {
    grid-area: 6 / 1 / 6 / 2;
  }

  .div15 {
    grid-area: 7 / 1 / 8 / 2;
  }

  .div16 {
    grid-area: 8 / 1 / 9 / 2;
  }

  .div18 {
    grid-area: 1 / 2 / 1 / 3;
  }

  .div19 {
    grid-area: 2 / 2 / 2 / 3;
  }

  .div20 {
    grid-area: 3 / 2 / 3 / 3;
  }

  .div21 {
    grid-area: 4 / 2 / 4 / 3;
  }

  .div22 {
    grid-area: 5 / 2 / 5 / 3;
  }

  .div23 {
    grid-area: 6 / 2 / 6 / 3;
  }

  .div24 {
    grid-area: 7 / 2 / 7 / 3;
  }

  .div26 {
    grid-area: 1 / 3 / 1 / 4;
  }

  .div27 {
    grid-area: 2 / 3 / 2 / 4;
  }

  .div28 {
    grid-area: 3 / 3 / 3 / 4;
  }

  .div29 {
    grid-area: 4 / 3 / 4 / 4;
  }

  .div30 {
    grid-area: 5 / 3 / 5 / 4;
  }

  .div31 {
    grid-area: 6 / 3 / 6 / 4;
  }

  .div32 {
    grid-area: 7 / 3 / 7 / 4;
  }

  .div34 {
    grid-area: 1 / 4 / 1 / 5;
  }

  .div35 {
    grid-area: 2 / 4 / 2 / 5;
  }

  .div36 {
    grid-area: 3 / 4 / 3 / 5;
  }

  .div37 {
    grid-area: 4 / 4 / 4 / 5;
  }

  .div38 {
    grid-area: 5 / 4 / 5 / 5;
  }

  .div39 {
    grid-area: 6 / 4 / 6 / 5;
  }

  .div40 {
    grid-area: 7 / 4 / 7 / 5;
  }

  .div41 {
    grid-area: 8 / 4 / 8 / 5;
  }

  .div42 {
    grid-area: 10 / 1 / 10 / 2;
  }

  .div43 {
    grid-area: 10 / 3 / 10 / 4;
  }

  .div44 {
    grid-area: 10 / 4 / 10 / 5;
  }

  .div45 {
    grid-area: 11 / 3 / 11 / 4;
  }

  .div46 {
    grid-area: 11 / 4 / 11 / 5;
  }

  .div47 {
    grid-area: 10 / 4 / 11 / 5;
  }

  .div48 {
    grid-area: 11 / 2 / 11 / 3;
  }

  .div49 {
    grid-area: 9 / 2 / 9 / 3;
  }

  .div50 {
    grid-area: 9 / 1 / 10 / 2;
  }

  .divAMoto {
    grid-area: 8 / 3 / 8 / 3;
  }

  .div51 {
    grid-area: 10 / 2 / 10 / 3;
  }

  .div52 {
    grid-area: 10 / 1 / 10 / 2;
  }

  .div54 {
    grid-area: 11 / 3 / 11 / 1;
  }

  .div55 {
    grid-area: 12 / 3 / 12 / 1;
  }

  .div60 {
    grid-area: 12 / 3 / 12 / 4;
  }

  .div61 {
    grid-area: 13 / 3 / 13 / 4;
  }

  .div62 {
    grid-area: 12 / 4 / 12 / 5;
  }

  .div63 {
    grid-area: 13 / 4 / 13 / 5;
  }
}

@media screen and (max-width: 1250px) {
  .grid-container-motorista {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
  }
}

@media screen and (max-width: 1100px) {
  .parent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(24, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 0px;
  }

  .div1 {
    grid-area: 1 / 1 / 9 / 2;
  }

  .div2 {
    grid-area: 9 / 1 / 19 / 2;
  }

  .div3 {
    grid-area: 1 / 2 / 10 / 3;
  }

  .div4 {
    grid-area: 10 / 2 / 19 / 3;
  }

  .div5 {
    grid-area: 19 / 1 / 21 / 3;
  }

  .div6 {
    grid-area: 23 / 1 / 21 / 3;
  }

  .div7 {
    grid-area: 23 / 1 / 25 / 3;
  }

  .div8 {
    grid-area: 1 / 1 / 2 / 2;
  }

  .div9 {
    grid-area: 1 / 1 / 1 / 2;
  }

  .div10 {
    grid-area: 2 / 1 / 2 / 2;
  }

  .div11 {
    grid-area: 3 / 1 / 3 / 2;
  }

  .div12 {
    grid-area: 4 / 1 / 4 / 2;
  }

  .div13 {
    grid-area: 5 / 1 / 5 / 2;
  }

  .div14 {
    grid-area: 6 / 1 / 6 / 2;
  }

  .div15 {
    grid-area: 7 / 1 / 7 / 2;
  }

  .div16 {
    grid-area: 8 / 1 / 8 / 2;
  }

  .div18 {
    grid-area: 9 / 1 / 9 / 2;
  }

  .div19 {
    grid-area: 10 / 1 / 10 / 2;
  }

  .div20 {
    grid-area: 11 / 1 / 11 / 2;
  }

  .div21 {
    grid-area: 12 / 1 / 12 / 2;
  }

  .div22 {
    grid-area: 13 / 1 / 13 / 2;
  }

  .div23 {
    grid-area: 14 / 1 / 14 / 2;
  }

  .div24 {
    grid-area: 15 / 1 / 15 / 2;
  }

  .div26 {
    grid-area: 1 / 2 / 1 / 3;
  }

  .div27 {
    grid-area: 2 / 2 / 2 / 3;
  }

  .div28 {
    grid-area: 3 / 2 / 3 / 3;
  }

  .div29 {
    grid-area: 4 / 2 / 4 / 3;
  }

  .div30 {
    grid-area: 5 / 2 / 5 / 3;
  }

  .div31 {
    grid-area: 6 / 2 / 6 / 3;
  }

  .div32 {
    grid-area: 7 / 2 / 7 / 3;
  }

  .divAMoto {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: 8 / 2 / 8 / 3;
  }

  .divSaldo {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: 9 / 2 / 9 / 3;
  }

  .div34 {
    grid-area: 10 / 2 / 10 / 3;
  }

  .div35 {
    grid-area: 11 / 2 / 11 / 3;
  }

  .div36 {
    grid-area: 12 / 2 / 12 / 3;
  }

  .div37 {
    grid-area: 13 / 2 / 13 / 3;
  }

  .div38 {
    grid-area: 14 / 2 / 14 / 3;
  }

  .div39 {
    grid-area: 15 / 2 / 15 / 3;
  }

  .div40 {
    grid-area: 16 / 2 / 16 / 3;
  }

  .divMotivo {
    grid-area: 17 / 2 / 17 / 3;
  }

  .div41 {
    grid-area: 18 / 2 / 18 / 3;
  }

  .div42 {
    grid-area: 19 / 1 / 19 / 2;
  }

  .div43 {
    grid-area: 21 / 1 / 21 / 2;
  }

  .div44 {
    grid-area: 21 / 2 / 21 / 3;
  }

  .div45 {
    grid-area: 22 / 1 / 22 / 2;
  }

  .div46 {
    grid-area: 22 / 2 / 22 / 3;
  }

  .div47 {
    grid-area: 21 / 2 / 22 / 3;
  }

  .div48 {
    grid-area: 22 / 2 / 23 / 3;
  }

  .div60 {
    grid-area: 23 / 1 / 23 / 2;
  }

  .div61 {
    grid-area: 24 / 1 / 25 / 2;
  }

  .div62 {
    grid-area: 23 / 2 / 24 / 3;
  }

  .div63 {
    grid-area: 24 / 2 / 25 / 3;
  }

  .div49 {
    grid-area: 19 / 2 / 19 / 3;
  }

  .div50 {
    grid-area: 19 / 1 / 19 / 2;
  }

  .div51 {
    grid-area: 20 / 2 / 20 / 3;
  }

  .div52 {
    grid-area: 20 / 1 / 20 / 2;
  }

  .div54 {
    grid-area: 23 / 1 / 23 / 3;
  }

  .div55 {
    grid-area: 24 / 1 / 24 / 3;
  }

  .divCheck {
    grid-area: 18 / 2 / 18 / 3;
  }
}

@media screen and (max-width: 920px) {
  .grid-container-motorista {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
  }
}

@media screen and (max-width: 600px) {
  .grid-container-motorista {
    display: grid;
    grid-template-columns: auto;
    padding: 10px;
  }

  .parent {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(43, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .div1 {
    grid-area: 1 / 1 / 44 / 2;
  }

  .div2 {
    display: none;
  }

  .div3 {
    display: none;
    grid-area: 2 / 1 / 3 / 2;
  }

  .div4 {
    display: none;
    grid-area: 3 / 1 / 4 / 2;
  }

  .div5 {
    display: none;
    grid-area: 4 / 1 / 5 / 2;
  }

  .div6 {
    display: none;
    grid-area: 5 / 1 / 6 / 2;
  }

  .div7 {
    display: none;
    grid-area: 6 / 1 / 7 / 2;
  }

  .div9 {
    grid-area: 1 / 1 / 1 / 2;
  }

  .div10 {
    grid-area: 2 / 1 / 2 / 2;
  }

  .div11 {
    grid-area: 3 / 1 / 3 / 2;
  }

  .div12 {
    grid-area: 4 / 1 / 4 / 2;
  }

  .div13 {
    grid-area: 5 / 1 / 5 / 2;
  }

  .div14 {
    grid-area: 6 / 1 / 6 / 2;
  }

  .div15 {
    grid-area: 7 / 1 / 7 / 2;
  }

  .div16 {
    grid-area: 8 / 1 / 8 / 2;
  }

  .div18 {
    grid-area: 9 / 1 / 9 / 2;
  }

  .div19 {
    grid-area: 10 / 1 / 10 / 2;
  }

  .div20 {
    grid-area: 11 / 1 / 11 / 2;
  }

  .div21 {
    grid-area: 12 / 1 / 12 / 2;
  }

  .div22 {
    grid-area: 13 / 1 / 13 / 2;
  }

  .div23 {
    grid-area: 14 / 1 / 14 / 2;
  }

  .div24 {
    grid-area: 15 / 1 / 15 / 2;
  }

  .div26 {
    grid-area: 16 / 1 / 16 / 2;
  }

  .div27 {
    grid-area: 17 / 1 / 17 / 2;
  }

  .div28 {
    grid-area: 18 / 1 / 18 / 2;
  }

  .div29 {
    grid-area: 19 / 1 / 19 / 2;
  }

  .div30 {
    grid-area: 20 / 1 / 20 / 2;
  }

  .div31 {
    grid-area: 21 / 1 / 21 / 2;
  }

  .div32 {
    grid-area: 22 / 1 / 22 / 2;
  }

  .divSaldo {
    grid-area: 23 / 1 / 23 / 2;
  }

  .div34 {
    grid-area: 24 / 1 / 24 / 2;
  }

  .div35 {
    grid-area: 25 / 1 / 25 / 2;
  }

  .div36 {
    grid-area: 26 / 1 / 26 / 2;
  }

  .div37 {
    grid-area: 27 / 1 / 27 / 2;
  }

  .div38 {
    grid-area: 28 / 1 / 28 / 2;
  }

  .div39 {
    grid-area: 29 / 1 / 39 / 2;
  }

  .div40 {
    grid-area: 30 / 1 / 30 / 2;
  }

  .divAMoto {
    grid-area: 31 / 1 / 31 / 2;
  }

  .divMotivo {
    grid-area: 32 / 1 / 32 / 2;
  }

  .divCheck {
    grid-area: 33 / 1 / 33 / 2;
  }

  .div43 {
    grid-area: 34 / 1 / 34 / 2;
  }

  .div44 {
    grid-area: 36 / 1 / 36 / 2;
  }

  .div45 {
    grid-area: 35 / 1 / 35 / 2;
  }

  .div46 {
    grid-area: 37 / 1 / 37 / 2;
  }

  .div49 {
    grid-area: 38 / 1 / 38 / 2;
  }

  .div50 {
    grid-area: 39 / 1 / 39 / 2;
  }

  .div51 {
    grid-area: 40 / 1 / 40 / 2;
  }

  .div52 {
    grid-area: 41 / 1 / 41 / 2;
  }

  .div54 {
    grid-area: 42 / 1 / 42 / 2;
  }

  .div55 {
    grid-area: 43 / 1 / 43 / 2;
  }

  .div60 {
    grid-area: 36 / 1 / 36 / 2;
  }

  .div61 {
    grid-area: 37 / 1 / 37 / 2;
  }

  .div62 {
    grid-area: 40 / 1 / 40 / 2;
  }

  .div63 {
    grid-area: 41 / 1 / 41 / 2;
  }
}

@media screen and (max-width: 800px) {
  .botoes-header {
    flex-direction: column;
    align-items: start;
    margin-left: 50%;
  }
}

@media screen and (max-width: 500px) {
  .botoes-header {
    margin-top: -10px;
  }
}

@media screen and (max-width: 1500px) {
  .filtro-embarque-v2 {
    flex-direction: column;
  }
}

.fat-botoes-embarque {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.fat-botoes-embarque-2 {
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
}

@media screen and (max-width: 1440px) {
  .indicador-principal-embarque-v2 {
    font-size: 26px;
  }

  .header-embarque-v2 {
    padding: 0px 40px 0 40px;
  }

  .filtro-embarque-v2 {
    padding: 0px 10px 0 35px;
  }

  .fat-botoes-embarque {
    display: none;
  }

  .fat-botoes-embarque-2 {
    display: flex;
  }
}

.container-embarque-card {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 15px;
  justify-content: center;
  margin-bottom: 10px;
}

.card-embarque {
  border: 1px solid #C3D8E3;
  background: #E8F7FF;
  border-radius: 10px;
  min-width: 190px;
  height: 50px;
  padding: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.card-header-embarque {
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Baby-Blue-Font, #002D45);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-body-embarque {
  text-overflow: ellipsis;
  color: var(--Baby-Blue-Font, #002D45);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.icon-calculate {
  border: 1px solid #000;
  border-radius: 20px;
  font-size: 30px;
  width: 35px !important;
  padding: 2px;
  height: 35px !important;
}