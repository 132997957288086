.box {
    display: flex;
}

.geometria-card {
    box-sizing: border-box;
    margin-top: 11px;
    position: absolute;
    left: 61%;
    z-index: 9999;
}

.search-box {
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 350px;
    height: 32px;
    margin-top: 15px;
    padding: 0 12px;
    border-radius: 3px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-size: 14px;
    outline: none;
    text-overflow: ellipses;
    position: absolute;
    left: 50%;
    margin-left: -165px;
    z-index: 9999;
}

.principal-mapa-v2 {
    width: 100%;
    height: 56vh;
}

.grid-item-cor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.header-cliente-v2 {
    display: flex;
    padding: 16px 40px 0 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.filtro-cliente-v2 {
    display: flex;
    padding: 15px 40px 0 30px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container-botoes-cliente {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    bottom: 0;
    position: absolute;
    width: 100%;
}

@media screen and (max-width: 700px) {
    .box2 {
        display: table-column;
    }
}

.indicador-principal {
    color: #4F4F4F;
    padding: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.indicador-principal-2 {
    color: #989898;
}

.indicador-secundario {
    color: #4F4F4F;
    margin-left: 10px;
}

.chrome-picker.picker {
    width: 250px !important;
}

.principal-cliente {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.botoes-cliente {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 3%;
    margin-bottom: 3%;
}