.container{
    width: 30vw;
}
.principal-codigo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.input-recuperar {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    width: 30vw;
    padding: 10px;
    margin: 0 calc(200px + 2vw) 0 0;
}

.div-card{
    width: 30vw;
}
.card {
    position: relative;
    right: 5vw;
    width: 30vw;
    margin: 20vh 20vw 0 0;
    border-radius: 15px;
    height: 80%;
    background-color: rgba(255, 255, 255, 0.7);
}

.texto-informe {
    text-align: center;
}

.botao-senhas {
    margin-top: 4vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 2vh;
}
@media (max-width: 768px) {
    .container{
        width: 100vw;
    }
    .div-card{
        width: 80vw;
    }
    .card {
        position: absolute;
        width: 80vw;
        margin: 0 auto;
        border-radius: 15px;
        height: 80%;
    }
    .input-recuperar {
        display: flex;
        flex-direction: column;
        gap: 2vh;
        width: 80vw;
        padding: 10px;
    }
}