.corpo-cores {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}

.header-configuracoes-v2 {
    display: flex;
    padding: 0 40px 20px 40px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.titulo-configs {
    margin-top: 2%;
    margin-bottom: 2%;
}

.grid-container-coluna-cidade {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    width: auto;
    background-color: rgba(124, 124, 124, 0.4);
    border-radius: 10px;
    margin: 10px;
}

.grid-container-coluna-embalagem {
    display: grid;
    grid-template-columns: auto;
    padding: 10px;
    width: auto;
    background-color: rgba(124, 124, 124, 0.4);
    border-radius: 10px;
    margin: 10px;
}

.grid-container-coluna-embarque {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: 10px;
    width: auto;
    background-color: rgba(124, 124, 124, 0.4);
    border-radius: 10px;
    margin: 10px;
}

.grid-container-coluna-empresa {
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 10px;
    width: auto;
    background-color: rgba(124, 124, 124, 0.4);
    border-radius: 10px;
    margin: 10px;
}

.grid-container-coluna-motorista {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding: 10px;
    width: auto;
    background-color: rgba(124, 124, 124, 0.4);
    border-radius: 10px;
    margin: 10px;
}


.grid-item-coluna-cidade {
    padding: 20px;
    font-size: 30px;
    text-align: center;
}

.grid-item-coluna-embarquepedido {
    padding: 20px;
    font-size: 18px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1550px) {
    .botoes-cores {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 3%;
    }

    .grid-container-coluna-embarque {
        grid-template-columns: auto auto auto auto;
    }
}

@media screen and (max-width: 1200px) {
    .grid-container-coluna-embarque {
        grid-template-columns: auto auto auto;
    }

    .grid-container-coluna-motorista {
        grid-template-columns: auto auto auto;
    }
}

@media screen and (max-width: 900px) {
    .grid-container-coluna-embarque {
        grid-template-columns: auto auto;
    }

    .grid-container-coluna-motorista {
        grid-template-columns: auto auto;
    }

    .grid-container-coluna-empresa {
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 670px) {
    .grid-container-coluna-embarque {
        grid-template-columns: auto;
    }

    .grid-container-coluna-motorista {
        grid-template-columns: auto;
    }

    .grid-container-coluna-empresa {
        grid-template-columns: auto;
    }

    .grid-container-coluna-cidade {
        grid-template-columns: auto;
    }
}