.image {
    background-image: url("../../../assets/images/buildings-1842205_1920.jpg");
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    position: absolute;
    filter: blur(2px);
    overflow-y: none !important;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-y: none !important;
    margin-top: 0px;
}

.card {
    position: relative;
    width: 25%;
    margin-top: 15%;
    height: 80%;
}

.input {
    display: flex;
    flex-direction: column;
    gap: 2vh;
    width: 100%;
}

.botao {
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
}

.cadastrese {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

a {
    font-size: 14px;
}

.p-card-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .card {
        width: 80%;
        margin-top: 15vh;
        height: 90%;
    }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
    .card {
        width: 50%;
        margin-top: 15vh;
        height: 90%;
    }
}

@media screen and (min-width: 1400px){
    .card {
        width: 17%;
        margin-top: 17%;
        height: 100%;
    }
}