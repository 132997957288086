/* fundo e layout geral */
.fundo {
    background: linear-gradient(135deg, #f0f4f8, #c9e0f3);
}

.principal-cards-feedback {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 20px; 
    width: 100%; 
    padding-top: 125px; 
    position: relative; /* posicionamento no fundo */
    background-image: url('../../../images/C1010_azul.png'); 
    background-size: 700px; /* Foi ajustado pra esse tamanho pois combinou melhor */
    background-position: center; 
    background-repeat: no-repeat; /* Evita a repetição da imagem */
    height: 100vh; 
    overflow: hidden; /* sem barras pra rolar */
}

.cardFeedback {
    background-color: rgba(249, 249, 248, 0.9); /* Fundo branco com transparencia pra logo aparecer */
    border-radius: 20px;
    padding: 20px; 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06);
    max-width: 300px;
    min-width: 250px; 
    height: 250px;
    transition: transform 0.2s, box-shadow 0.2s;
}



/* estilização do card tbm */
.cardFeedback {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribui espaço entre os elementos */
    background-color: #f9f9f8;
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06); /* Faz os cards flexíveis e de tamanho igual */
    max-width: 300px; /* Largura máxima para os cards */
    min-width: 250px; /* Largura mínima */
    height: 400px; /* Altura fixa para todos os cards */
    transition: transform 0.2s, box-shadow 0.2s;
}
.cardFeedback {
    background-color: rgba(249, 249, 248, 0.9); /* aumentada transparencia */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.06);
    max-width: 300px;
    min-width: 250px;
    height: 400px;
    transition: transform 0.2s, box-shadow 0.2s;
}
.cardFeedback:hover {
    transform: scale(1.10);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15), 0 8px 16px rgba(0, 0, 0, 0.1);
}
.cardFeedback h3 {
    margin-bottom: 10px; /* implementei algo especifico pros H3 pra diferenciar bem o "titulo" do paragráfo */
    text-align: center;
    font-size: 1.5rem;
    padding-top: 10px; 
    font-weight: bold;
}

.TextoCard {
    flex-grow: 1; /* pega o espaço disponivel */
    text-align: center;
    display: flex;
    align-items: center; /* centraliza verticalmente */
    justify-content: center; /* centraliza horizontalmente tbm*/
    margin-top: 10px; /* espaço no topo */
}

.TextoCardContato {
        margin-top: 100px;  /* Estava complicado lidar com esse card em especifico então achei de boa praticar separar a estilização dele*/
        display: flex;
        flex-direction: column; 
        align-items: flex-start; 
        gap: 10px; 
        text-align: left; 
    }


/* Estilo pra versão*/
.versao {
    font-weight: bold; 
    color: #888;
    font-size: 1.5rem;
    margin-top: 10px; 
}

/*responsividade*/
@media screen and (max-width: 900px) {
    .principal {
        flex-direction: column; /* Empilha os cards verticalmente em telas pequenas */
        align-items: center; /* Centraliza os cards empilhados */
    }

    .cardFeedback {
        width: 90%; /* Cards em largura total em telas menores */
    }

    .titulo {
        font-size: 1.4rem; /* Ajusta o tamanho do título */
    }
}
